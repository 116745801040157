import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import user, { loginAdmin } from "../../js/auth";
import { createCookie } from "../../js/cookies";
import CircularIndeterminate from "../../components/CircularProgress";

const Login = () => {

    const [done, setDone] = useState(false);
    var url = useLocation().pathname;

    const [loading, setLoading] = useState(false);

    const handleAuth = async () => {
        
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;
        if (email !== '' && password !== '') {
            setLoading(true);

            var user = {
                EMAIL: email,
                PASSWORD: password,
            }

            var res = await loginAdmin(user);

            if (res.success) {
                console.log(res)
                user.ADMIN_ID = res.user.ADMIN_ID;
                user.NAME = res.user.USERNAME;
                user.ROLE = res.user.ROLE;
                createCookie("admin", JSON.stringify(user), 1);
                alert("Logged in successfully");
                window.location.reload();
            }
            else {
                alert("Error: " + res.message);
            }
        }
        else {
            alert("Please enter all the fields");
        }

        setLoading(false);

    }

    useEffect(() => {
        if (!done) {
            if (url !== '/') {
                window.location.replace('/');
                setDone(true);
            }
        }
    }, []);

    return (
        <section onKeyDown={(e) => {
            if (e.key === 'Enter') {
                handleAuth();
            }
        }} id="auth">
            <div className="left">
                <div className="left-cont">

                    <img src={process.env.PUBLIC_URL + "assets/logo_new.png"} alt="" />
                    <h1>LiveOPD</h1>
                    <p>Welcome to LiveOPD Admin Panel</p>


                </div>



            </div>
            <div className="right">
                <div className="right-cont">
                    <div className="mb-logo">
                        <img src={process.env.PUBLIC_URL + "assets/logo_new.png"} alt="" />
                    </div>
                    <br />
                    <br />
                    <h4>Admin Login</h4>
                    <br />
                    <br />
                    <div className="auth-form-cont">
                        <label htmlFor="email">
                            Email:
                            <input placeholder="Enter your email" type="email" name="email" id="email" />
                        </label>
                        <br />
                        <br />
                        <label htmlFor="password">
                            Password:
                            <input placeholder="Enter your password" type="password" name="password" id="password" />
                        </label>
                        <br />
                        <br />

                        {
                        loading ?
                               <div style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                                marginTop: 15,
                                padding: 14,
                                borderRadius: 10,
                                backgroundColor: '#2d55a8'
                               }} >
                                 <CircularIndeterminate />
                               </div>
                                :
                                <input onClick={() => {
                                    handleAuth();
                                }} className="auth-btn" type="button" value={'LogIn'}/>
                        }
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Login;