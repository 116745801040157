import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import PanelHead from "./components/PanelHead";
import { getSchemes, getTypeId, postSchemeMasterData } from "../../../js/formsDB";
import { ViewSchemeMaster } from "./View/ViewSchemeMaster";
import { countries } from "../../../js/countries";

const SchemeMaster = () => {
  const [done, setDone] = useState(false);

  const [hideMain, setHideMain] = useState(false);

  const [schemeMasters, setSchemeMasters] = useState([]);

  const [types, setTypes] = useState([]);

  useEffect(() => {
    getMastersLength();
  }, []);

  const getMastersLength = async () => {
    var res = await getSchemes();

    if (res.success) {
      setSchemeMasters(res.schemeMaster);
      var ct = res.maxid + 1;
      var cm = res.schemeMaster.filter((item) => item.ID === ct);
      if (cm.length > 0) {
        ct = ct + 1;
      }
      document.getElementById("schemeID").value = ct;
      var res2 = await getTypeId();
      if (res2.success) {
        console.log(res2)
        var typeMasters = res2.typeMaster;
        typeMasters = typeMasters.filter((item) => item.IS_ACTIVE === true);
        typeMasters = typeMasters.filter((item) => item.MASTER_NAME === "Scheme Type");
        var ty = [];
        typeMasters.forEach((item) => {
          ty.push(item.TYPE_NAME);
        }
        );
        setTypes(ty);
      }
    } else {
      alert("Error: " + res.message);
    }
  };

  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
  };

  const handleSubmit = async () => {
    var schemeName = document.getElementById("schemeName").value;
    var mcount = document.getElementById("count").value;
    var amount = document.getElementById("amount").value;
    var noofconsulation = document.getElementById("noofconsulation").value;
    var valid_month = document.getElementById("valid_month").value;
    var ref = document.getElementById("percentage").value;

    if (schemeName === "") {
      alert("Enter Scheme Name");
      return;
    }

    if (amount === "") {
      alert("Enter Amount");
      return;
    }
    if (valid_month === "") {
      alert("Enter Valid Days");
      return;
    }
    if(ref === ""){
      alert("Enter Ref.%");
      return;
    }

    if(mcount === ""){
      alert("Enter Count");
      return;
    }

    if(noofconsulation === ""){
      alert("Enter No of Consultations");
      return;
    }

    if(valid_month === ""){
      alert("Enter Valid Days");
      return;
    }


    var is_active = document.querySelector(
      "input[name = isactive]:checked"
    ).value;

    const data = {
      SCHEME_NAME: document.getElementById("schemeName").value,
      COUNTRY_CODE: parseInt(document.getElementById("countryCode").value),
      COUNT: parseInt(mcount),
      AMOUNT: parseInt(amount),
      VALID_DAYS: parseInt(valid_month),
      NO_OF_CONSULTATIONS: parseInt(noofconsulation),
      NARRATION: document.getElementById("narration").value,
      IS_ACTIVE: is_active === "yes",
      PERCENTAGE: parseInt(document.getElementById("percentage").value),
      TYPE_OF_SUBSCRIPTION : document.getElementById("schemeType").value
    };

    var res = await postSchemeMasterData(data);

    if (res.success) {
      alert("Scheme Master Added Successfully");
      await getMastersLength();
    } else {
      alert("Error: " + res.message);
    }
  };

  return (
    <div className="panel">
      {!hideMain && (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          {" "}
          <PanelHead title={"Scheme Master"} />
          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-2">
                  <label htmlFor="schemeID">
                    Scheme ID: <br />
                    <input
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="schemeID"
                      placeholder="Scheme ID"
                      id="schemeID"
                    />
                  </label>
                </div>

                <div className="col-7">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="schemeName"
                  >
                    Scheme Name: <br />
                    <input
                      type="text"
                      name="schemeName"
                      placeholder="Scheme Name"
                      id="schemeName"
                    />
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="schemeType"
                  >
                    Type: <br />
                    <select name="schemeType" id="schemeType">
                      {
                        types.map((item, i) => {
                          return (
                            <option value={item}>{item}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-2 col-sm-12">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="countryCode"
                  >
                    Code: <br />
                    <select name="countryCode" id="countryCode">
                      {countries.map((item, i) => {
                        return (
                          <option
                            selected={item.dial_code.replace("+", "") === "91"}
                            value={item.dial_code.replace("+", "")}
                          >
                            {item.dial_code.replace("+", "") +
                              " - " +
                              item.name}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="count"
                  >
                    Member: <br />
                    <input
                      type="number"
                      name="count"
                      placeholder="Enter Member Count"
                      id="count"
                    />
                  </label>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="amount"
                  >
                    Amount: <br />
                    <input
                      type="number"
                      name="amount"
                      placeholder="Enter Amount"
                      id="amount"
                    />
                  </label>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="noofconsulation"
                  >
                    No Cons: <br />
                    <input
                      type="number"
                      name="noofconsulation"
                      placeholder="Enter Number of Consultations"
                      id="noofconsulation"
                    />
                  </label>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="valid_month"
                  >
                    Valid Days: <br />
                    <input
                      type="number"
                      name="valid_month"
                      placeholder="Enter Valid Days"
                      id="valid_month"
                    />
                  </label>
                </div>
                <div
                  className="col-lg-2 col-sm-12"
                >
                   <label 
                    style={{
                      color: "red",
                    }} htmlFor="percentage">
                Ref.%: <br />
                <input
                onChange={(e)=>{
                  if(e.target.value < 0){
                    document.getElementById("percentage").value = 0;
                  }
                }}
                  type="number"
                  name="percentage"
                  placeholder="Ref.%"
                  id="percentage"
                />
              </label>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-10">
                <label htmlFor="narration">
                Narration: [seperation through dot(.)]<br />
                <textarea
                style={{
                  width: "100%",
                  height: 100,
                  border: '1px solid #212121',
                  borderRadius: 8,
                  padding: 5,
                  outline:'none'
                }}
                  row="10"
                  type="text"
                  name="narration"
                  placeholder="Enter Narration"
                  id="narration"
                />
              </label>
                </div>

                <div  style={{
                    textAlign: "center",
                    display: "flex",
                    position: "relative",
                    top: 10,
                    justifyContent: "center",
                  }} className="col-2">
              <div>
                    <h6>Is Active: </h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isactive"
                          defaultChecked
                          id="isactive"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isactive"
                          id="isactive"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="button"
                onClick={handleSubmit}
                className="auth-btn"
                value="Add Table"
              />
            </div>
          </div>
        </div>
      )}

      <ViewSchemeMaster
        masters={schemeMasters}
        randT={Math.floor(Math.random() * 100000)}
        getM={getMastersLength}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default SchemeMaster;
