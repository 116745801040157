import { Component, React, useState } from "react";
import { getCookie } from "../js/cookies";
import Login from "../pages/auth/Login";
import { checkUser } from "../js/auth";


class ProtectedRoute extends Component{
    constructor(props){
        super(props);
        this.loggedin = false;
        this.user = {};
    }

   

   async componentDidMount(){
        var res = await checkUser();
        if(res.success){
            this.loggedin = true;
            this.setState({})
        }
        else{
            // alert(res.message)
        }
    }

    render(){
        if(this.loggedin){
                return this.props.children;
        }
        else{
            return <Login />;
        }
    }
}

export default ProtectedRoute;