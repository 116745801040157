
import axios from 'axios';


axios.defaults.withCredentials = true;

const config = {headers: { 
    "Content-Type": "application/json"
}};


const imageConfig = {headers: {'Content-Type': 'multipart/form-data'}};

const urlHead = 'https://server.liveopd.in/api/v2/';
// const urlHead = 'http://localhost:8000/api/v2/';



export const loginAdmin = async(map) => {
    const url = urlHead + 'loginAdmin';
    try{
        const response = await axios.post(url, map, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const fetchBalance = async () => {
    const url = urlHead + 'get-balance';
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const getAllCallHistories = async () => {
    const url = urlHead + 'getCallLogs';
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}

export const getTransactionLogs = async () => {
    const url = urlHead + 'getAllPaymentLogs';
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}



export const logOutAdmin = async() => {
    const url = urlHead + 'logOutAdmin';
    try{
        const response = await axios.get(url, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const checkUser = async() => {
    const url = urlHead + 'me';
    try{
        const response = await axios.get(url, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const changePassword = async(user, map) => {
    const url = urlHead + 'changePassword/'+user;
    try{
        const response = await axios.put(url, map, config);
        const body = await response.data;
        return body;
    } catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}


export const getPrescriptionByPId = async (id) => {
    const url = urlHead + 'getPrescriptionById/'+id;
    try{
        const response = await axios.get(url,config);
        const body = await response.data;
        return body;
    }
    catch(e){
        var error = await e.response.data.message;
        return {success: false, message: error};
    }
}