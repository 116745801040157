import React, { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { getTransactionLogs } from "../../../../js/auth";
import { getCookie } from "../../../../js/cookies";
import { UpdatePrescription } from "../components/UpdatePrescription";

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button
        style={{ height: 50, borderRadius: 0 }}
        className="btn btn-success"
        onClick={handleClick}
      >
        Export
      </button>
    </div>
  );
};

const formatDateTime = (datetime) => {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const ViewTransactionLogs = (props) => {
  const [calls, setCalls] = useState([]);
  const [backup, setBackup] = useState([]);
  const [order, setOrder] = useState("asc");
  const [searchType, setSearchType] = useState("id");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [presId, setPresId] = useState(null);
  const [showPres, setShowPres] = useState(false);
  const [admin, setAdmin] = useState({});

  const acolumns = [
    { text: "ID", dataField: "id", sort: true },
    { text: "PD ID", dataField: "PD_ID", sort: true },
    { text: "LM MAIN ID", dataField: "LM_MAIN_ID", sort: true },
    { text: "NAME", dataField: "LM_NAME", sort: true },
    { text: "MOBILE", dataField: "LM_MOBILE", sort: true },
    { text: "TRANSACTION ID", dataField: "TRANSACTION_ID", sort: true },
    { text: "UPI ID", dataField: "UPI_ID", sort: true },
    {
      text: "Date",
      dataField: "updatedAt",
      sort: true,
      formatter: (cellContent) => formatDateTime(cellContent), // Formatter for Date column
    },
    {
      text: "Amount",
      dataField: "TRANSACTION_AMOUNT",
      sort: true,
      formatter: (cellContent, row) => (row.IS_CREDIT ? "" : cellContent),
      headerFormatter: () => "Debit",
    },
    {
      text: "Amount",
      dataField: "TRANSACTION_AMOUNT",
      sort: true,
      formatter: (cellContent, row) => (row.IS_CREDIT ? cellContent : ""),
      headerFormatter: () => "Credit",
    },
  ];
  
  const defaultSorted = [
    { dataField: "updatedAt", order: "asc" }, // Correct dataField for sorting by date
  ];
  const getDate = async () => {
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth() + 1;
    const year = d.getFullYear();
    if (day.toString().length === 1) day = "0" + day;
    if (month.toString().length === 1) month = "0" + month;
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    return formattedDate;
  };

  const filterUsingDate = (start, endD) => {
    const s_start = start;
    const s_end = endD;
    const sd = new Date(s_start).getTime();
    let ed = new Date(s_end);
    ed.setDate(ed.getDate() + 1);
    ed = ed.getTime();
    const filteredCalls = backup.filter((d) => {
      const time = new Date(d.createdAt).getTime();
      return sd < time && time < ed;
    });
    setCalls(filteredCalls);
  };

  const getCallHistory = async (start, end) => {
    const res = await getTransactionLogs();
    if (res.success) {
      const t_calls = res.data;
      setBackup(t_calls);
      const s_start = start === null ? await getDate() : start;
      const s_end = end === null ? await getDate() : end;
      const sd = new Date(s_start).getTime();
      let ed = new Date(s_end);
      ed.setDate(ed.getDate() + 1);
      ed = ed.getTime();
      const filteredCalls = t_calls.filter((d) => {
        const time = new Date(d.createdAt).getTime();
        return sd < time && time < ed;
      });
      setCalls(filteredCalls);
    }
  };

  useEffect(() => {
    const a = getCookie("admin");
    if (a !== "") {
      setAdmin(JSON.parse(a));
    }
    getDate().then(() => getCallHistory(null, null));
  }, []);

  const totalDebit = calls.reduce((sum, call) => sum + (call.IS_CREDIT ? 0 : call.TRANSACTION_AMOUNT), 0);
  const totalCredit = calls.reduce((sum, call) => sum + (call.IS_CREDIT ? call.TRANSACTION_AMOUNT : 0), 0);
  const netTotal = totalCredit - totalDebit;

  return (
    !showPres ? (
      <div
        style={{ margin: 10, border: "3px solid #212121" }}
        className="panels"
      >
        <PanelHead disableMenu={true} title={"View Call Register"} />
        <div style={{ padding: "15px" }}>
          <div
            style={{
              height: "100vh",
              position: "fixed",
              zIndex: 2,
              right: 0,
              paddingTop: 70,
              overflowY: "auto",
              boxShadow: "0 0 8px gray",
              backgroundColor: "#fff",
            }}
            className="prescription-cont-outside"
          />
          <div className="panel_cont">
            <ToolkitProvider keyField="id" data={calls} columns={acolumns} exportCSV>
              {(props) => (
                <div>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <span style={{ marginRight: 10, position: "relative", top: 5, fontWeight: 600 }}>
                      FROM -{" "}
                    </span>
                    <input
                      id="startDate"
                      style={{ padding: 5 }}
                      type="date"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        filterUsingDate(e.target.value, document.getElementById("endDate").value);
                      }}
                      value={startDate}
                    />
                    <span style={{ marginRight: 10, marginLeft: 10, position: "relative", top: 5, fontWeight: 600 }}>
                      TO -{" "}
                    </span>
                    <input
                      id="endDate"
                      style={{ padding: 5 }}
                      type="date"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        filterUsingDate(document.getElementById("startDate").value, e.target.value);
                      }}
                      value={endDate}
                    />
                  </div>
                  <br />
                  <div style={{ display: "flex", height: 50, justifyContent: "flex-end" }}>
                    <select onChange={(e) => setSearchType(e.target.value)} defaultValue={"status"}>
                      <option value="id">ID</option>
                      <option value="PD_ID">PD_ID</option>
                      <option value="LM_MAIN_ID">LM MAIN ID</option>
                      <option value="TRANSACTION_ID">TRANSACTION ID</option>
                      <option value="TRANSACTION_AMOUNT">AMOUNT</option>
                      <option value="SCHEME_ID">SCHEME ID</option>
                      <option value="REFERRED_ID">REF ID</option>
                      <option value="IS_CREDIT">IS CREDIT</option>
                    </select>
                    <input
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          const filteredCalls = calls.filter((item) => {
                            if (item[searchType] !== null) {
                              return item[searchType].toString().toLowerCase().includes(e.target.value.toLowerCase());
                            }
                            return false;
                          });
                          setCalls(filteredCalls);
                        } else {
                          if (order === "asc") {
                            setCalls(backup);
                            filterUsingDate(startDate, endDate);
                          } else {
                            setCalls(backup.reverse());
                            filterUsingDate(startDate, endDate);
                          }
                        }
                      }}
                      placeholder="Search Transaction Log"
                      style={{ width: "100%", height: 50, border: "1px solid black", outline: "none", padding: "5px" }}
                      type="text"
                    />
                    {admin.ROLE === 'admin' && <MyExportCSV {...props.csvProps} />}
                  </div>
                  <hr />
                  <BootstrapTable
                    hover
                    striped
                    condensed
                    headerWrapperClasses="foo"
                    pagination={paginationFactory()}
                    {...props.baseProps}
                  />
                  <div style={{ marginTop: 20, padding: 10, borderTop: "2px solid #212121", textAlign: "right" }}>
                    <div><strong>Total Credit:</strong> {totalCredit.toFixed(2)}</div>
                    <div><strong>Total Debit:</strong> {totalDebit.toFixed(2)}</div>
                    <div><strong>Net Total (Credit - Debit):</strong> {netTotal.toFixed(2)}</div>
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    ) : (
      <UpdatePrescription id={presId} closeModal={() => setShowPres(false)} />
    )
  );
};
