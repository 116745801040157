import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  deleteItemMaster,
  deleteSchemeMaster,
  getItemsId,
  getSchemes,
  getTypeId,
  updateItemMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export const ViewItemMaster = (props) => {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({});

  const changeMode = (mode, data) => {
    setData(data);
    setMode(mode);
  };

  if (mode === "view") {
    return <ViewItemMaster1 {...props} setMode={changeMode} />;
  } else if (mode === "edit") {
    return <EditItemMaster {...props} data={data} setMode={changeMode} />;
  }
};

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button style={{
        height: 50,
        borderRadius: 0
      }} className="btn btn-success" onClick={handleClick}>
        Export
      </button>
    </div>
  );
};

export const ViewItemMaster1 = (props) => {
  const [masters, setMasters] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [prevGroup, setPrevGroup] = useState("");

  const [searchType, setSearchType] = useState("ITEM_NAME");

  const [randomT, setRandomT] = useState(0);

  const [done, setDone] = useState(false);


  const columns = [
    {
      dataField: "ITEM_ID",
      text: "ITEM ID",
      sort: true,
    },
    {
      dataField: "ITEM_NAME",
      text: "ITEM NAME",
      sort: true,
    },
    {
      dataField: "ITEM_GRP_ID",
      text: "DEPT ID",
      sort: true,
    },
    {
      dataField: "DEPARTMENT",
      text: "DEPARTMENT",
      sort: true,
    },
    {
      dataField: "ITEM_GRP",
      text: "ITEM GROUP",
      sort: true,
    },
    {
      dataField: "GENERIC_NAME",
      text: "GENERIC NAME",
      sort: true,
      hidden: true
    },
    {
      dataField: "DOSAGE_INTERVAL",
      text: "DOSAGE INTERVAL",
      sort: true,
      hidden: true
    },
    {
      dataField: "MANUFACTURER",
      text: "MANUFACTURER",
      sort: true,
      hidden: true
    },
    {
      dataField: "IS_ACTIVE",
      text: "ACTIVE",
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BsFillPencilFill
            onClick={() => {
              props.setMode("edit", row);
              props.setHideMain(true);
            }}
            style={{
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "#2d55a8",
            }}
          />
          <MdDelete
            onClick={async () => {
              var y = window.confirm(
                "Are you sure you want to delete this record?"
              );
              if (y) {
                var res = await deleteItemMaster(row.ITEM_ID);
                if (res.success) {
                  alert("Deleted Successfully");
                  var t = masters.filter((m) => {
                    return m.ITEM_ID !== row.ITEM_ID;
                  });

                  setMasters(t);
                  var tb = backup.filter((m) => {
                    return m.ID !== row.ID;
                  });
                  setBackup(tb);
                  props.getM();
                } else {
                  alert("Error Occured");
                }
              }
            }}
            style={{
              marginLeft: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "red",
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.masters.length !== 0) {
      if (randomT !== props.randT) {
        var l = props.masters;

        setPrevGroup(props.currentGroupName);
        l = l.filter((item) => item.DEPARTMENT === props.currentGroupName);
        setMasters(l);
        setBackup(l);
        setDone(true);
        setRandomT(props.randT);
      }
      if (prevGroup !== props.currentGroupName) {
        var s = props.masters;

        setPrevGroup(props.currentGroupName);
        s = s.filter((item) => item.DEPARTMENT === props.currentGroupName);
        setMasters(s);
        setBackup(s);
      }
    }
  });
  return (
    <div
      style={{
        marginTop: 50,
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead disableMenu={true} title={"View Item Master"} />
      <div
        style={{
          padding: "15px",
        }}
      >
        <div className="panel_cont">

          <ToolkitProvider
            keyField="id"
            data={masters}
            columns={columns}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    height: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"ITEM_NAME"}
                  >
                    <option value="ITEM_ID">ITEM ID</option>
                    <option value="ITEM_NAME">ITEM NAME</option>
                    <option value="DEPARTMENT">DEPARTMENT</option>
                    <option value="ITEM_GRP_ID">DEPARTMENT ID</option>
                    <option value="ITEM_GRP">ITEM GROUP</option>
                    <option value="IS_ACTIVE">ACTIVE</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = masters;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setMasters(l);
                      } else {
                        if (order === "asc") {
                          setMasters(backup);
                        } else {
                          setMasters(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search Item Master"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />

                  <MyExportCSV {...props.csvProps} />
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          {/* <table id="customers">
          <thead>
            <tr>
              <th>ITEM ID</th>
              <th style={{
                width: 300
              }}>ITEM NAME</th>
              <th>DEPARTMENT</th>
              <th>GROUP ID</th>
              <th style={{
                width: 300
              }}>ITEM GROUP</th>
              <th>ACTIVE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {masters.map((master) => {
              return (
                <tr>
                  <td>{master.ITEM_ID}</td>
                  <td>{master.ITEM_NAME}</td>
                  <td>{master.DEPARTMENT}</td>
                  <td>{master.ITEM_GRP_ID}</td>
                  <td>{master.ITEM_GRP}</td>
                  <td>{master.IS_ACTIVE !== undefined ? master.IS_ACTIVE.toString() : 'true'}</td>
                  <td>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <BsFillPencilFill
                        onClick={() => {
                          props.setMode("edit", master);
                          props.setHideMain(true);
                        }}
                        style={{
                          marginRight: "10px",
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "#2d55a8",
                        }}
                      />
                      <MdDelete
                        onClick={async () => {
                          var y = window.confirm(
                            "Are you sure you want to delete this record?"
                          );
                          if (y) {
                            var res = await deleteItemMaster(master.ITEM_ID);
                            if (res.success) {
                              alert("Deleted Successfully");
                              var t = masters.filter((m) => {
                                return m.ITEM_ID !== master.ITEM_ID;
                              });
                              setMasters(t);
                              props.getM();
                            } else {
                              alert("Error Occured");
                            }
                          }
                        }}
                        style={{
                          marginLeft: "10px",
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
        </div>
      </div>
    </div>
  );
};

const EditItemMaster = (props) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [genericName, setGenericName] = useState([]);
  const [dosageInterval, setDosageInterval] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);

  const [itemMasters, setItemMasters] = useState([]);

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done) {
      getMastersLength();
      setDone(true);
    }
  });

  const getGroupId = (temp, name) => {
    var count = 0;
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].DEPARTMENT === name) {
        count++;
      }
    }
    return count;
  };

  const getAllData = async (itemMaster) => {
    var res2 = await getTypeId();
    if (res2.success) {
      var m = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Manufacturer" && item.IS_ACTIVE === true
      );
      var g = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Generic Name" && item.IS_ACTIVE === true
      );
      var d = res2.typeMaster.filter(
        (item) =>
          item.MASTER_NAME === "Dosage Interval" && item.IS_ACTIVE === true
      );
      var d1 = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Department" && item.IS_ACTIVE === true
      );
      var i = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Item Group" && item.IS_ACTIVE === true
      );
      setManufacturers(m);
      setGenericName(g);
      setDosageInterval(d);
      setItemGroups(i);
      setDepartments(d1);
    }
  };

  const getMastersLength = async () => {
    var res = await getItemsId();

    if (res.success) {
      setItemMasters(res.itemMaster);
    }

    await getAllData(res.itemMaster);
  };

  const handleEdit = async () => {
    var itemName = document.getElementById("itemName").value;
    var is_active = document.querySelector(
      "input[name = e_isactive]:checked"
    ).value;

    if (itemName === "") {
      alert("Enter an Item Name");
      return;
    }

    var data = {
      ITEM_GRP_ID: parseInt(document.getElementById("e_itemGroupID").value),
      ITEM_GRP: document.getElementById("itemGroup").value,
      ITEM_NAME: itemName,
      GENERIC_NAME: document.getElementById("genericName").value,
      MANUFACTURER: document.getElementById("manufacturer").value,
      DOSAGE_INTERVAL: document.getElementById("dosageInterval").value,
      DEPARTMENT: document.getElementById("department").value,
      IS_ACTIVE: is_active === "yes" ? true : false,
    };

    var res = await updateItemMaster(data, props.data.ITEM_ID);

    if (res.success) {
      alert("Updated Successfully");
      props.getM();
      props.setMode("view");
      props.setHideMain(false);
    } else {
      alert("Error Occured");
    }
  };

  return (
    <div
      style={{
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead
        showIcon={true}
        setHideMain={props.setHideMain}
        setMode={props.setMode}
        title={"Edit Item Master"}
      />

      <div className="panel_cont">
        <div style={{ display: "flex" }} className="head"></div>
        <br />
        <div
          style={{
            padding: 15,
          }}
          className="edit-form"
        >
          <div className="row">
            <div className="col-2">
              <label htmlFor="e_itemID">
                Item ID: <br />
                <input
                  readOnly={true}
                  defaultValue={props.data.ITEM_ID}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  type="text"
                  name="e_itemID"
                  placeholder="Item ID"
                  id="e_itemID"
                />
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="e_itemGroupID">
                Dept ID: <br />
                <input
                  type="text"
                  defaultValue={props.data.ITEM_GRP_ID}
                  readOnly={true}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  name="e_itemGroupID"
                  id="e_itemGroupID"
                />
              </label>
            </div>

            <div className="col">
              <label htmlFor="department">
                Department: <br />
                <select onChange={(e) => {
                  if (e.target.value === props.data.DEPARTMENT) {
                    document.getElementById("e_itemGroupID").value =
                      props.data.ITEM_GRP_ID;
                  } else {
                    var c = getGroupId(itemMasters, e.target.value);
                    document.getElementById("e_itemGroupID").value = c + 1;
                  }
                }} name="department" id="department">
                  {departments.map((item) => {
                    return (
                      <option
                        selected={props.data.DEPARTMENT === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="col">
              <label htmlFor="itemGroup">
                Item Group: <br />
                <select
                  name="itemGroup"
                  id="itemGroup"
                >
                  {itemGroups.map((item) => {
                    return (
                      <option
                        selected={props.data.ITEM_GRP === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                top: 10,
              }}
              className="col-3"
            >
              <div>
                <h6>Is Active: </h6>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginRight: "20px",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_isactive"
                      defaultChecked={props.data.IS_ACTIVE}
                      id="e_isactive"
                      value={"yes"}
                    />
                    <span style={{ marginLeft: "3px" }}>Yes</span>
                  </label>

                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_isactive"
                      id="e_isactive"
                      defaultChecked={!props.data.IS_ACTIVE}
                      value={"no"}
                    />
                    <span style={{ marginLeft: "3px" }}>No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col">
              <label
                style={{
                  color: "red",
                }}
                htmlFor="itemName"
              >
                Item Name: <br />
                <input
                  type="text"
                  name="itemName"
                  defaultValue={props.data.ITEM_NAME}
                  placeholder="Enter Item Name"
                  id="itemName"
                />
              </label>
            </div>

            <div className="col">
              <label htmlFor="genericName">
                Generic Name: <br />
                <select name="genericName" id="genericName">
                  {genericName.map((item) => {
                    return (
                      <option
                        selected={props.data.GENERIC_NAME === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col">
              <label htmlFor="dosageInterval">
                Dosage Interval: <br />
                <select name="dosageInterval" id="dosageInterval">
                  {dosageInterval.map((item) => {
                    return (
                      <option
                        selected={props.data.DOSAGE_INTERVAL === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="col">
              <label htmlFor="manufacturer">
                Manufacturer: <br />
                <select name="manufacturer" id="manufacturer">
                  {manufacturers.map((item) => {
                    return (
                      <option
                        selected={props.data.MANUFACTURER === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>

          <input
            type="button"
            onClick={handleEdit}
            className="auth-btn"
            value="Update Table"
          />
        </div>
      </div>
    </div>
  );
};
