import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import PanelHead from "./components/PanelHead";
import {
  getMastersId,
  getTypeId,
  postTypeMasterData,
} from "../../../js/formsDB";
import { ViewTypeMaster } from "./View/ViewTypeMaster";

const TypeMaster = () => {
  const [masters, setMasters] = useState([]);

  const [typeMasters, setTypeMasters] = useState([]);

  const [remarkHide, setRemarkHide] = useState(false);

  const [parentHide, setParentHide] = useState(false);

  const [hideMain, setHideMain] = useState(false);

  const [currentMasterName, setCurrentMasterName] = useState("");

  useEffect(() => {
    getMastersLength();
  }, []);

  const getDisplayId = (temp, name) => {
    var idArray = [];
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].MASTER_NAME === name) {
        idArray.push(temp[i].TYPE_DISPLAY_ID);
      }
    }
    var maxId = 0;
    if(idArray.length > 0){
      maxId = Math.max(...idArray);
    }
    return maxId;
  };

  const getMastersLength = async () => {
    var res = await getTypeId();

    if (res.success) {
      setTypeMasters(res.typeMaster);

      var ct = res.maxid + 1;
      var cm = res.typeMaster.filter((item) => item.TYPE_ID === ct);
      if (cm.length > 0) {
        ct = ct + 1;
      }
      document.getElementById("typeID").value = ct;
    }

    var res2 = await getMastersId();

    if (res2.success) {
      var l = res2.typeNameMaster;
      l = l.filter((item) => item.IS_ACTIVE === true);
      setMasters(l);
      if(currentMasterName !== "" && currentMasterName !== 0){
        var c = getDisplayId(res.typeMaster, l[0].MASTER_TYPE_NAME);
        document.getElementById("typeDisplayID").value = c + 1;
      }
      else{
      document.getElementById("typeDisplayID").value = 0;
      }
      setRemarkHide(l[0].MASTER_REMARKS_DISPLAY);
      setParentHide(l[0].MASTER_IS_GROUP);
    }
  };

  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
  };

  const handleSubmit = async () => {
    var did = document.getElementById("typeDisplayID").value;
    var typename = document.getElementById("typename").value;
    var remarks1 = "";
    var remarks2 = "";

    if (did === "") {
      alert("Enter Type Display ID");
      return;
    }
    if (typename === "") {
      alert("Enter Type Name");
      return;
    }

    if(document.getElementById("masterName").value === "0"){
      alert("Select a master name");
      return;
    }

    var is_active = document.querySelector(
      "input[name = isActive]:checked"
    ).value;
    var is_default = document.querySelector(
      "input[name = isDefault]:checked"
    ).value;
    var is_delete = document.querySelector(
      "input[name = isDelete]:checked"
    ).value;
    const data = {
      TYPE_DISPLAY_ID: parseInt(did),
      MASTER_NAME: document.getElementById("masterName").value,
      TYPE_NAME: typename,
      TYPE_IS_DEFAULT: is_default === "yes",
      IS_ACTIVE: is_active === "yes",
      TYPE_IS_DELETE: is_delete === "yes",
    };

    if (remarkHide) {
      remarks1 = document.getElementById("remarks1").value;
      remarks2 = document.getElementById("remarks2").value;
      data["TYPE_REMARKS_1"] = remarks1;
      data["TYPE_REMARKS_2"] = remarks2;
    }

    if (parentHide) {
      var parent = document.getElementById("parentGroup").value;
      data["TYPE_PARENT_GROUP"] = parent;
    }

    var res = await postTypeMasterData(data);

    if (res.success) {
      alert("Type Master Added Successfully");
      document.getElementById("typename").value = "";
      document.getElementById("masterName").value = "0";
      setCurrentMasterName(0);
      await getMastersLength();
    } else {
      alert("Error: " + res.message);
    }
  };

  return (
    <div className="panel">
      {!hideMain && (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          <PanelHead title={"Type Master"} />

          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col">
                  <label htmlFor="typeID">
                    Type ID: <br />
                    <input
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="typeID"
                      placeholder="Type ID"
                      id="typeID"
                    />
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="typeDisplayID">
                    Type Display ID: <br />
                    <input
                      type="text"
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      name="typeDisplayID"
                      placeholder="Type Display ID"
                      id="typeDisplayID"
                    />
                  </label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label htmlFor="masterName">
                    Master Name: <br />
                    <select
                      onChange={(e) => {
                        if(e.target.value !== "0"){
                          var s = masters.filter(
                            (item) => item.MASTER_TYPE_NAME === e.target.value
                          );
                          setCurrentMasterName(e.target.value);
                          setRemarkHide(s[0].MASTER_REMARKS_DISPLAY);
                          setParentHide(s[0].MASTER_IS_GROUP);
                          var c = getDisplayId(typeMasters, e.target.value);
                          document.getElementById("typeDisplayID").value = c + 1;
                        } 
                        else{
                          var s = [];
                          setCurrentMasterName(0);
                          setRemarkHide(false);
                          setParentHide(false);
                          document.getElementById("typeDisplayID").value = 0;
                        }
                      }}
                      name="masterName"
                      id="masterName"
                    >
                       <option value={0}>---SELECT---</option>
                      {masters.map((item, i) => {
                        return (
                          <option value={item.MASTER_TYPE_NAME}>
                            {item.MASTER_TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="typename"
                  >
                    Type Name: <br />
                    <input
                      type="text"
                      name="typename"
                      placeholder="Enter Type Name"
                      id="typename"
                    />
                  </label>
                </div>
              </div>
              <br />
              {parentHide ? (
                <>
                  <label htmlFor="parentGroup">
                    Type Parent Group: <br />
                    <select name="parentGroup" id="parentGroup">
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </label>
                  <br />
                  <br />
                </>
              ) : null}
              {remarkHide ? (
                <>
                  <label htmlFor="remarks1">
                    Type Remarks 1: <br />
                    <input
                      type="text"
                      name="remarks1"
                      placeholder="Enter Type Remarks 1"
                      id="remarks1"
                    />
                  </label>
                  <br />
                  <br />
                  <label htmlFor="remarks2">
                    Type Remarks 2: <br />
                    <input
                      type="text"
                      name="remarks2"
                      placeholder="Enter Type Remarks 2"
                      id="remarks2"
                    />
                  </label>
                  <br />
                  <br />
                </>
              ) : null}

              <div className="row">
                <div className="col">
                  <h6 style={{ fontWeight: 600 }}>Is Default</h6>
                  <div style={{ marginTop: "10px", display: "flex" }}>
                    <label
                      style={{
                        width: "fit-content",
                        display: "flex",
                        marginRight: "20px",
                      }}
                    >
                      <input
                        type="radio"
                        name="isDefault"
                        id="isDefault"
                        value={"yes"}
                      />
                      <span style={{ marginLeft: "3px" }}>Yes</span>
                    </label>

                    <label
                      style={{
                        width: "fit-content",
                        display: "flex",
                      }}
                    >
                      <input
                        type="radio"
                        name="isDefault"
                        defaultChecked
                        id="isDefault"
                        value={"no"}
                      />
                      <span style={{ marginLeft: "3px" }}>No</span>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  className="col"
                >
                  <div>
                    <h6 style={{ fontWeight: 600 }}>Is Delete</h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isDelete"
                          defaultChecked
                          id="isDelete"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isDelete"
                          id="isDelete"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                  className="col"
                >
                  <div>
                    <h6 style={{ fontWeight: 600 }}>Is Active</h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isActive"
                          defaultChecked
                          id="isActive"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isActive"
                          id="isActive"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <input
                type="button"
                onClick={handleSubmit}
                className="auth-btn"
                value="Add Table"
              />
            </div>
          </div>
        </div>
      )}

      <ViewTypeMaster
        getM={getMastersLength}
        randT={Math.floor(Math.random() * 1000000) + 1}
        masters={typeMasters}
        currentMasterName={currentMasterName}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default TypeMaster;
