import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import PanelHead from "./components/PanelHead";
import { getActiveSchemes, getFreeAccessLedgers, getSchemes, getTypeId, postFreeAccessLedger, postSchemeMasterData } from "../../../js/formsDB";
import { ViewSchemeMaster } from "./View/ViewSchemeMaster";
import { countries } from "../../../js/countries";
import { ViewSpecialPeople } from "./View/ViewSpecialPeople";
import * as XLSX from 'xlsx';
import { useRef } from "react";

const SpecialPeople = () => {
  const [done, setDone] = useState(false);

  const [hideMain, setHideMain] = useState(false);

  const [schemeMasters, setSchemeMasters] = useState([]);

  const [schemes, setSchemes] = useState([]);

  const [freeAccessLedgers, setFreeAccessLedgers] = useState([]);

  const [companyNames, setCompanyNames] = useState([]);
  const [companyNamesLess, setCompanyNamesLess] = useState([]);

  const [types, setTypes] = useState([]);

  const [multiUpload, setMultiUpload] = useState(false);

  const [multiFiles, setMultiFiles] = useState([]);

  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Assume first sheet is the one we want
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Parse the sheet into JSON
      const rows = XLSX.utils.sheet_to_json(sheet);

      // Extract specific fields
      const extractedData = rows.map(row => ({
        MOBILE_NUMBER: parseInt(row['MOBILE NUMBER']),
        COMPANY_NAME: row['COMPANY NAME'],
        SCHEME_NAME: parseInt(row['SCHEME']),
        NARRATION: row['NARRATION'],
        IS_EXPIRE: row["IS_EXPIRE"] === 1
      }));

      // Update state
      setMultiFiles(extractedData);
      setMultiUpload(true);

      console.log(extractedData)
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    getMastersLength();
  }, []);

  const getMastersLength = async () => {
    var res = await getFreeAccessLedgers();

    if (res.success) {

      setFreeAccessLedgers(res.freeAccessLedgers);
      var resscheme = await getActiveSchemes();
      if (resscheme.success) {
        setSchemeMasters(resscheme.schemeMaster);

        var sy = [];
        resscheme.schemeMaster.forEach((item) => {
          sy.push(item.ID + " - " + item.SCHEME_NAME);
        });

        setSchemes(sy);
      }
      var ct = res.maxid + 1;
      var cm = res.freeAccessLedgers.filter((item) => item.ID === ct);
      console.log("cmm==>");
      console.log(cm)
      if (cm.length > 0) {
        ct = ct + 1;
      };



      document.getElementById("faID").value = ct;
      var res2 = await getTypeId();
      if (res2.success) {
        console.log(res2)
        var typeMasters = res2.typeMaster;
        typeMasters = typeMasters.filter((item) => item.IS_ACTIVE === true);
        var comp = typeMasters.filter((item) => item.MASTER_NAME === 'Company Name');
        var cy = [];
        comp.forEach((item) => {
          cy.push(item.TYPE_ID + " - " + item.TYPE_NAME);
        });
        cy.unshift("None");
        typeMasters = typeMasters.filter((item) => item.MASTER_NAME === "Scheme Type");
        var ty = [];
        typeMasters.forEach((item) => {
          ty.push(item.TYPE_NAME); 
        }
        );
        setTypes(ty);
        setCompanyNames(cy);
      }
    } else {
      alert("Error: " + res.message);
    }
  };

  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
    setMultiFiles([]);
    setMultiUpload(false);
  };

  const handleSubmit = async () => {


    if (multiUpload) {

      console.log("multifile==>")
      console.log(multiFiles)

      var res = await postFreeAccessLedger({
        datas: multiFiles
      });

      if (res.success) {
        alert("Free Access Ledgers Added Successfully");
        await getMastersLength();
      } else {
        alert("Error: " + res.message);
      }
    }
    else {
      var mobilenumber = document.getElementById("mobilenumber").value;
      var companyname = document.getElementById("companyname").value;
      var schemeid = document.getElementById("schemeid").value;
      var narration = document.getElementById("narration").value;

      var is_expire = document.querySelector(
        "input[name = isexpire]:checked"
      ).value;



      if (mobilenumber === "") {
        alert("Enter Mobile Number");
        return;
      }

      if (schemeid === "") {
        alert("Select a Scheme");
        return;
      }


      if (companyname === "None") {
        companyname = ""
      }
      else {
        companyname = companyname.split(" - ")[1];
      }

      schemeid = parseInt(schemeid.split(" - ")[0]);


      const data = {
        MOBILE_NUMBER: parseInt(mobilenumber),
        COMPANY_NAME: companyname,
        SCHEME_NAME: schemeid,
        NARRATION: narration,
        IS_EXPIRE: is_expire === "yes"
      };

      var res = await postFreeAccessLedger({
        datas: [data]
      });

      if (res.success) {
        alert("Free Access Ledger Added Successfully");
        await getMastersLength();
        document.getElementById("mobilenumber").value = ""
      } else {
        alert("Error: " + res.message);
      }
    }


  };



  return (
    <div className="panel">
      {!hideMain && (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          {" "}
          <PanelHead title={"Free Access"} />
          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-2">
                  <label htmlFor="faID">
                    ID: <br />
                    <input
                      readOnly={true}
                      disabled={multiUpload}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="faID"
                      placeholder="ID"
                      id="faID"
                    />
                  </label>
                </div>

                <div className="col-4">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="mobilenumber"
                  >
                    Mobile Number: <br />
                    <input
                      readOnly={multiUpload}
                      style={{
                        backgroundColor: multiUpload ? "#e0e0e0" : 'white',
                      }}
                      type="text"
                      name="mobilenumber"
                      placeholder="Mobile Number"
                      id="mobilenumber"
                    />
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="schemeid"
                  >
                    Scheme: <br />
                    <select disabled={multiUpload} style={{
                      backgroundColor: multiUpload ? "#e0e0e0" : 'white',
                    }} name="schemeid" id="schemeid">
                      {
                        schemes.map((item, i) => {
                          return (
                            <option value={item}>{item}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </label>
                </div>

              </div>


              <br />
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="companyname"
                  >
                    Company Name: <br />
                    <select disabled={multiUpload} style={{
                      backgroundColor: multiUpload ? "#e0e0e0" : 'white',
                    }} name="companyname" id="companyname">
                      {
                        companyNames.map((item, i) => {
                          return (
                            <option value={item}>{item}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="">Upload Excel {multiUpload ? ' - ' : ''} {multiUpload ? <span onClick={() => {
                    setMultiFiles([]);
                    setMultiUpload(false);
                    fileInputRef.current.value = '';
                  }} style={{
                    color: 'red'
                  }}>Remove</span> : ''}</label>
                  <input ref={fileInputRef} onChange={handleFileUpload} type="file" name="browseFA" id="browseFA" />
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-10">
                  <label htmlFor="narration">
                    Narration: [seperation through dot(.)]<br />
                    <textarea
                      style={{
                        width: "100%",
                        height: 100,
                        border: '1px solid #212121',
                        borderRadius: 8,
                        padding: 5,
                        outline: 'none',
                        backgroundColor: multiUpload ? "#e0e0e0" : 'white',
                      }}
                      disabled={multiUpload}
                      row="10"
                      type="text"
                      name="narration"
                      placeholder="Enter Narration"
                      id="narration"
                    />
                  </label>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    position: "relative",
                    top: 10,
                    justifyContent: "center",
                  }}
                  className="col-2"
                >
                  <div>
                    {/* <h6>Is Expire: </h6> */}
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "none", // Hides the radio button and label
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isexpire"
                          defaultChecked
                          id="isexpire"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "none", // Hides the radio button and label
                        }}
                      >
                        <input
                          type="radio"
                          name="isexpire"
                          id="isexpire"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <input
                type="button"
                onClick={handleSubmit}
                className="auth-btn"
                value={`Add ${multiUpload ? 'All Table' : 'Table'}`}
              />
            </div>
          </div>
        </div>
      )}

      <ViewSpecialPeople
        masters={freeAccessLedgers}
        schemes={schemes}
        companyNames={companyNames}
        randT={Math.floor(Math.random() * 100000)}
        getM={getMastersLength}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default SpecialPeople;
