import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  addLedgerMaster,
  deleteLedgerMasterDataById,
  deleteSchemeMaster,
  deleteTypeMaster,
  getCities,
  getCountries,
  getMastersId,
  getSchemes,
  getStates,
  getTypeId,
  updateLedgerMasters,
  updateLedgerPhotos,
  updateSchemeMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { AiFillCamera } from "react-icons/ai";
import Compressor from "compressorjs";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { getCookie } from "../../../../js/cookies";



export const ViewLedgerMaster = (props) => {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({});

  const [admin, setAdmin] = useState({});

  const changeMode = (mode, data) => {
    setData(data);
    setMode(mode);
  };

  if (mode === "view") {
    return <ViewLedgerMaster1 {...props} setMode={changeMode} />;
  } else if (mode === "edit") {
    return <EditLedgerMaster {...props} data={data} setMode={changeMode} />;
  }
};

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button style={{
        height: 50,
        borderRadius: 0
      }} className="btn btn-success" onClick={handleClick}>
        Export
      </button>
    </div>
  );
};

export const ViewLedgerMaster1 = (props) => {
  const [masters, setMasters] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [searchType, setSearchType] = useState("LM_NAME");

  const [randomT, setRandomT] = useState(0);

  const [done, setDone] = useState(false);

  const [type, setType] = useState(null);

  const [admin, setAdmin] = useState({});

  const dcolumns = [
    {
      dataField: "LM_ID",
      text: admin.ROLE === 'admin' ?  "LM ID" : "ID",
      sort: true,
    },
    {
      dataField: "LM_UNDER_GROUP_ID",
      text: "GROUP ID",
      sort: true,
      hidden: admin.ROLE === 'admin' ? false : true
    },
    {
      dataField: "LM_NAME",
      text: "NAME",
      sort: true,
    },
    {
      dataField: "SPECIALITY",
      text: "SPECIALITY",
      sort: true,
      hidden: true
    },
    {
      dataField: "REGISTRATION_NO",
      text: "REGISTRATION NO",
      sort: true,
      hidden: true
    },
    {
      dataField: "QUALIFICATION",
      text: "QUALIFICATION",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_ADDRESS",
      text: "ADDRESS 2",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_COUNTRY",
      text: "COUNTRY",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_MOBILE",
      text: "MOBILE",
      sort: true,
    },
    {
      dataField: "LM_PHONE",
      text: "PHONE",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_CITY",
      text: "CITY",
      sort: true,
    },
    {
      dataField: "LM_STATE",
      text: "STATE",
      sort: true,
    },

    {
      dataField: "LM_EMAIL_ID",
      text: "EMAIL",
      sort: true,
      hidden: true
    },

    {
      dataField: "LM_PAN_NO",
      text: "PAN NO",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_GST_NO",
      text: "GST NO",
      sort: true,
      hidden: true
    },
    {
      dataField: "LM_AADHAR_NO",
      text: "AADHAR NO",
      sort: true,
      hidden: true
    },
    {
      dataField: "IS_ACTIVE",
      text: "ACTIVE",
      sort: true,
      hidden: admin.ROLE === 'admin' ? false : true
    },
    {
      dataField: "IS_AGENT",
      text: "AGENT",
      formatter: (cell, row, index) => {
        return <IsAgentRow row={row} />
      },
      // hidden: admin.ROLE === 'admin' ? true : false
    },
    {
      dataField: "actions",
      hidden: admin.ROLE === 'admin' ? false : true,
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BsFillPencilFill
            onClick={() => {
              props.setMode("edit", row);
              props.setHideMain(true);
            }}
            style={{
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "#2d55a8",
            }}
          />
          {/* <MdDelete
            onClick={async () => {
              var y = window.confirm(
                "Are you sure you want to delete this record?"
              );
              if (y) {
                var res = await deleteLedgerMasterDataById(row.LM_REFERENCE_ID);
                if (res.success) {
                  alert("Deleted Successfully");
                  var t = masters.filter((m) => {
                    return m.LM_REFERENCE_ID !== row.LM_REFERENCE_ID;
                  });
                  setMasters(t);
                  var tb = backup.filter((m) => {
                    return m.ID !== row.ID;
                  });
                  setBackup(tb);
                  props.getM();
                } else {
                  alert("Error Occured");
                }
              }
            }}
            style={{
              marginLeft: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "red",
            }}
          /> */}
        </div>
      ),
    },
  ];
 

  const IsAgentRow = (props) => {
    const [isAgent, setIsAgent] = useState(props.row.IS_AGENT);
    const GreenSwitch = styled(Switch)(({ theme }) => ({
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: green[600],
        "&:hover": {
          backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
      },
      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: green[600],
      },
    }));

    return <GreenSwitch checked={isAgent} onChange={async (e) => {
      setIsAgent(e.target.checked);
      var res = await updateLedgerMasters(
        {
          IS_AGENT: e.target.checked
        },
        props.row.LM_REFERENCE_ID
      );
      if (res.success) {
        alert("Updated Successfully");
      } else {
        alert("Error Occured");
      }
    }} />
  }

  useEffect(() => {
    var a = getCookie("admin");
    if (a !== "") {
      a = JSON.parse(a);
      setAdmin(a);
    }
    if (props.masters.length !== 0) {
      if (randomT !== props.randT) {
        if (admin.ROLE === 'admin') {
          var l = props.masters;
          l = l.filter((item) => item.LM_UNDER_GROUP === props.currentGrpName);
          setMasters(l);
          setBackup(l);
          setRandomT(props.randT);
        }
        else {
          var l = props.masters;
          l = l.filter((item) => item.LM_UNDER_GROUP === "Patient");
          setMasters(l);
          setBackup(l);
          setRandomT(props.randT);
        }
      }
    }
  });
  return (
    <div
      style={{
        marginTop: admin.ROLE === 'admin' ? 50 : 0,
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead disableMenu={true} title={"View Ledger Master"} />
      <div
        style={{
          padding: "15px",
        }}
      >

        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={masters}
            columns={dcolumns}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    height: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"LM_NAME"}
                  >
                    <option value="LM_REFERENCE_ID">LM ID</option>
                    <option value="LM_NAME">NAME</option>
                    <option value="LM_MOBILE">MOBILE</option>
                    <option value="LM_STATE">CITY</option>
                    <option value="LM_CITY">STATE</option>
                    {
                      masters.length > 0 && masters[0].LM_UNDER_GROUP === "Patient" && (
                        <option value="IS_AGENT">AGENT</option>
                      )
                    }
                    <option value="IS_ACTIVE">ACTIVE</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = masters;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setMasters(l);
                      } else {
                        if (order === "asc") {
                          setMasters(backup);
                        } else {
                          setMasters(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search Ledger Master"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                  <MyExportCSV {...props.csvProps} />
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          {/* <table id="customers">
            <thead>
              <tr>
                <th>LM ID</th>
                <th>GROUP ID</th>
                <th>UNDER GRP</th>
                <th
                  style={{
                    width: 250,
                  }}
                >
                  NAME
                </th>
                <th>ALIAS</th>
                <th>MOBILE</th>
                <th>ACTIVE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {masters.map((master) => {
                return (
                  <tr>
                    <td>{master.LM_REFERENCE_ID}</td>
                    <td>{master.LM_UNDER_GROUP_ID}</td>
                    <td>{master.LM_UNDER_GROUP}</td>
                    <td>{master.LM_NAME}</td>
                    <td>{master.LM_ALIAS}</td>
                    <td>{master.LM_MOBILE}</td>
                    <td>{master.IS_ACTIVE.toString()}</td>
                    <td>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <BsFillPencilFill
                          onClick={() => {
                            props.setMode("edit", master);
                            props.setHideMain(true);
                          }}
                          style={{
                            marginRight: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#2d55a8",
                          }}
                        />
                        <MdDelete
                          onClick={async () => {
                            var y = window.confirm(
                              "Are you sure you want to delete this record?"
                            );
                            if (y) {
                              var res = await deleteLedgerMasterDataById(
                                master.LM_REFERENCE_ID
                              );
                              if (res.success) {
                                alert("Deleted Successfully");
                                var t = masters.filter((m) => {
                                  return m.LM_REFERENCE_ID !== master.LM_REFERENCE_ID;
                                });
                                setMasters(t);
                                props.getM();
                              } else {
                                alert("Error Occured");
                              }
                            }
                          }}
                          style={{
                            marginLeft: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

const EditLedgerMaster = (props) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [signaturePhoto, setSignaturePhoto] = useState(null);

  const [specialties, setSpecialties] = useState([]);

  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [ledgertype, setLedgertype] = useState("");

  const [masters, setMasters] = useState([]);

  const [done, setDone] = useState(false);

  const [mainMasters, setMainMasters] = useState([]);


  const getGroupId = (temp, name) => {
    var count = 0;
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].LM_UNDER_GROUP === name) {
        count++;
      }
    }
    return count;
  };

  const getMainMasters = async () => {
    var res2 = await getTypeId();

    if (res2.success) {
      var m = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Specialty" && item.IS_ACTIVE === true
      );
      var index = m.findIndex((obj) => obj.TYPE_IS_DEFAULT === "0");
      m.unshift(m.splice(index, 1)[0]);

      setSpecialties(m);
    }
  };

  const fetchCountries = async () => {
    var res = await getCountries();
    if (res.success) {
      console.log(res.countriesData)
      setCountries(res.countriesData);
    }
  };

  const fetchStates = async (countryId) => {
    var res = await getStates(countryId);
    if (res.success) {
      setStates(res.statesData);
    }
  };

  const fetchCities = async (stateId) => {
    var res = await getCities(stateId);
    if (res.success) {
      setCities(res.citiesData);
    }
  };

  useEffect(() => {
    setMasters(props.masters);
    if (!done) {
      getMainMasters();
      setDone(true);
    }
  });

  useEffect(() => {
    fetchCountries();
    fetchStates(props.data.LM_COUNTRY);
    fetchCities(props.data.LM_STATE);
    setLedgertype(props.data.LM_UNDER_GROUP);
  }, []);

  const handleEdit = async () => {
    const check = ["dname", "mobile", "email",];
    if (ledgertype === "Doctor") {
      const check = ["dname", "mobile", "email", "regNo"];
    }
  
    

    for (var i = 0; i < check.length; i++) {
      if (document.getElementById(check[i]).value === "") {
        alert("Please fill all mandatory fields");
        return;
      }
    }

    var is_active = document.querySelector(
      "input[name = isActive]:checked"
    ).value;


    var specialty = document.getElementById("specialty").value;
    var spt = specialties.filter((item) => item.TYPE_NAME === specialty);
    spt = spt[0].TYPE_ID;


    const data = {
      LM_UNDER_GROUP_ID: document.getElementById("e_groupID").value,
      LM_UNDER_GROUP: document.getElementById("groupName").value,
      LM_REFERENCE_ID: parseInt(document.getElementById("refID").value),
      LM_NAME: document.getElementById("dname").value,
      LM_ALIAS: document.getElementById("alias").value,
      LM_ADDRESS: document.getElementById("address1").value,
      LM_CITY: document.getElementById("city").value,
      LM_STATE: document.getElementById("state").value,
      LM_COUNTRY: parseInt(document.getElementById("country").value),
      QUALIFICATION: document.getElementById("qualification").value,
      LM_MOBILE: document.getElementById("mobile").value,
      LM_PHONE: document.getElementById("phone").value === "" ? "" : document.getElementById("phone").value,
      SPECIALITY: specialty,
      REGISTRATION_NO: document.getElementById("regNo").value,
      LM_EMAIL_ID: document.getElementById("email").value,
      LM_PAN_NO: document.getElementById("LM_PAN_NO").value,
      LM_GST_NO: document.getElementById("LM_GST_NO").value,
      LM_AADHAR_NO: document.getElementById("LM_AADHAR_NO").value,
      IS_ACTIVE: is_active === "yes" ? true : false,
    };

    if (profilePhoto) {
      data.profile = profilePhoto;
    }

    if (signaturePhoto) {
      data.signature = signaturePhoto;
    }

    var res = await updateLedgerMasters(data, props.data.LM_REFERENCE_ID);

    if (res.success) {
      alert(data.LM_UNDER_GROUP + " updated successfully");
      props.getM();
      props.setMode("view");
      props.setHideMain(false);
    } else {
      alert("Error while uploading profile info: " + res.message);
    }
  };

  return (
    <div
      style={{
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <input
        id="e_profilePhoto"
        type="file"
        style={{
          display: "none",
        }}
        onChange={() => {
          console.log("===============<1");
          const input = document.getElementById("e_profilePhoto");
          console.log("===============<1");
          var upload_image = "";

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            upload_image = reader.result;
            document.getElementById("e_profile_photo_img").src = upload_image;
          });
          const image = input.files[0];
          const max_size = 1000000;
          const max_width = 2000;
          const max_height = 2000;
          const compress_image = new Compressor(image, {
            quality: 0.6,
            maxWidth: max_width,
            maxHeight: max_height,
            convertSize: max_size,
            success: (result) => {
              console.log("mahoooooooooooooooooooooooooooo==========>");
              const file = new File([result], result.name, {
                type: "image/jpeg",
              });

              reader.readAsDataURL(file);
              setProfilePhoto(file);
            },
          });
        }}
      />

      <input
        id="e_signaturePhoto"
        type="file"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          console.log("===============<1");
          const input = document.getElementById("e_signaturePhoto");

          var upload_image = "";

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            console.log("ssss===========>");
            upload_image = reader.result;
            document.getElementById("e_signature_photo_img").src = upload_image;
          });
          const image = input.files[0];
          const max_size = 1000000;
          const max_width = 2000;
          const max_height = 2000;
          const compress_image = new Compressor(image, {
            quality: 0.6,
            maxWidth: max_width,
            maxHeight: max_height,
            convertSize: max_size,
            success: (result) => {
              const file = new File([result], result.name, {
                type: "image/jpeg",
              });

              reader.readAsDataURL(file);
              setSignaturePhoto(file);
            },
          });
        }}
      />

      <PanelHead
        showIcon={true}
        setHideMain={props.setHideMain}
        setMode={props.setMode}
        title={"Edit Ledger Master"}
      />
      <div className="panel_cont">
        <div style={{ display: "flex" }} className="head"></div>
        <br />
        <div
          style={{
            padding: 15,
          }}
          className="edit-form"
        >
          <div className="row">
            <div className="col-2">
              <label htmlFor="e_lm_reference_id">
                Master ID: <br />
                <input
                  readOnly={true}
                  defaultValue={props.data.LM_ID}
                  type="text"
                  name="e_lm_reference_id"
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  placeholder="Ledger Master"
                  id="e_lm_reference_id"
                />
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="e_groupID">
                Group ID: <br />
                <input
                  type="text"
                  defaultValue={props.data.LM_UNDER_GROUP_ID}
                  readOnly={true}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  name="e_groupID"
                  placeholder="Enter Under Group ID"
                  id="e_groupID"
                />
              </label>
            </div>
            <div className="col-2">
              <label htmlFor="refID">
                Ref ID: <br />
                <input
                  type="text"
                  readOnly={true}
                  defaultValue={props.data.LM_REFERENCE_ID}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  name="refID"
                  placeholder="Enter Reference ID"
                  id="refID"
                />
              </label>
            </div>
            <div className="col-2">
              <div>
                <h6 style={{ fontWeight: 600 }}>Is Active</h6>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginRight: "20px",
                    }}
                  >
                    <input
                      type="radio"
                      name="isActive"
                      defaultChecked={props.data.IS_ACTIVE}
                      id="isActive"
                      value={"yes"}
                    />
                    <span style={{ marginLeft: "3px" }}>Yes</span>
                  </label>

                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <input
                      type="radio"
                      name="isActive"
                      id="isActive"
                      defaultChecked={!props.data.IS_ACTIVE}
                      value={"no"}
                    />
                    <span style={{ marginLeft: "3px" }}>No</span>
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="col-4"
            >
              <div className="row">
                <div
                  style={{
                    marginRight: "50px",
                  }}
                  className="col"
                >
                  <div
                    onClick={() => {
                      document.getElementById("e_profilePhoto").click();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    id="profile_photo"
                    className="profile_photo photo_col"
                  >
                    {props.data.LM_PHOTO !== "" ? (
                      <img
                        src={props.data.LM_PHOTO}
                        alt="profile"
                        id="e_profile_photo_img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <AiFillCamera
                          style={{
                            fontSize: "30px",
                            color: "#6d6d6d",
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Profile Photo
                  </p>
                </div>
                {ledgertype === "Doctor" && (
                  <div className="col">
                    <div
                      onClick={() => {
                        document.getElementById("e_signaturePhoto").click();
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      id="signature_photo"
                      className="signature_photo photo_col"
                    >
                      {props.data.LM_SIGNATURE !== "" ? (
                        <img
                          id="e_signature_photo_img"
                          src={props.data.LM_SIGNATURE}
                          alt="signature"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <AiFillCamera
                            style={{
                              fontSize: "30px",
                              color: "#6d6d6d",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Signature Photo
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="groupName">
                Under Group Name: <br />
                <select
                  onChange={(e) => {
                    setLedgertype(e.target.value);
                    var c = getGroupId(props.data, e.target.value);
                    document.getElementById("groupID").value = c + 1;
                  }}
                  name="groupName"
                  id="groupName"
                  disabled={true}
                >
                  <option
                    selected={props.data.LM_UNDER_GROUP === "Doctor"}
                    value="Doctor"
                  >
                    Doctor
                  </option>
                  <option
                    selected={props.data.LM_UNDER_GROUP === "Patient"}
                    value="Patient"
                  >
                    Patient
                  </option>
                </select>
              </label>
            </div>

            <div className="col">
              <label style={{
                color: "red"
              }} htmlFor="specialty">
                Specialty: <br />
                <select name="specialty" id="specialty"  disabled={ledgertype !== "Doctor"}>
                  {specialties.map((item, index) => {
                    return (
                      <option
                        selected={props.data.SPECIALITY === item.TYPE_NAME}
                        value={item.TYPE_NAME}
                      >
                        {item.TYPE_NAME}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="col">
              <label htmlFor="regNo">
                Reg No: <br />
                <input
                  readOnly={ledgertype !== "Doctor"}
                  type="text"
                  defaultValue={props.data.REGISTRATION_NO}
                  name="regNo"
                  placeholder="Enter Registration No"
                  id="regNo"
                />
              </label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <label
                style={{
                  color: "red",
                }}
                htmlFor="dname"
              >
                Name: <br />
                <input
                  type="text"
                  defaultValue={props.data.LM_NAME}
                  name="dname"
                  placeholder="Enter Name"
                  id="dname"
                />
              </label>
            </div>
            <div className="col">
              <label htmlFor="alias">
                Alias: <br />
                <input
                  type="text"
                  name="alias"
                  defaultValue={props.data.LM_ALIAS}
                  placeholder="Enter Alias"
                  id="alias"
                />
              </label>
            </div>

            <div className="col">
              <label style={{
                color: "red"
              }} htmlFor="qualification">
                Qualification: <br />
                <input
                  readOnly={ledgertype !== "Doctor"}
                  type="text"
                  name="qualification"
                  defaultValue={props.data.QUALIFICATION}
                  placeholder="Enter Qualification"
                  id="qualification"
                />
              </label>
            </div>
          </div>
          <br />
          <label htmlFor="address1">
            Address: <br />
            <input
              type="text"
              name="address1"
              defaultValue={props.data.LM_ADDRESS}
              placeholder="Enter Address 1"
              id="address1"
            />
          </label>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <label htmlFor="country">
                Country: <br />
                <select
                  onChange={(e) => {
                    fetchStates(e.target.value);
                  }}
                  name="country"
                  id="country"
                >
                  {
                    console.log(countries)
                  }
                  {countries.map((item, index) => {
                    return (
                      <option
                        selected={props.data.LM_COUNTRY === parseInt(item.phonecode)}
                        value={parseInt(item.phonecode)}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="col">
              <label htmlFor="state">
                State: <br />
                <select
                  onChange={(e) => {
                    fetchCities(e.target.value);
                  }}
                  name="state"
                  id="state"
                >
                  {states.map((item, index) => {
                    return (
                      <option
                        selected={props.data.LM_STATE === item.name}
                        value={item.name}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="col">
              <label htmlFor="city">
                City: <br />
                <select name="city" id="city">
                  {cities.map((item, index) => {
                    return (
                      <option
                        selected={props.data.LM_CITY === item.name}
                        value={item.name}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <label
                style={{
                  color: "red",
                }}
                htmlFor="email"
              >
                Email: <br />
                <input
                  type="text"
                  defaultValue={props.data.LM_EMAIL_ID}
                  name="email"
                  placeholder="Enter Email"
                  id="email"
                />
              </label>
            </div>
            <div className="col">
              <label
                style={{
                  color: "red",
                }}
                htmlFor="mobile"
              >
                Mobile: <br />
                <input
                  type="text"
                  name="mobile"
                  defaultValue={props.data.LM_MOBILE}
                  placeholder="Enter Mobile"
                  id="mobile"
                />
              </label>
            </div>

            <div className="col">
              <label htmlFor="phone">
                Phone: <br />
                <input
                  type="text"
                  defaultValue={props.data.LM_PHONE}
                  name="phone"
                  placeholder="Enter Phone"
                  id="phone"
                />
              </label>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col">
              <label htmlFor="LM_PAN_NO">
                Pan No: <br />
                <input
                  type="text"
                  name="LM_PAN_NO"
                  defaultValue={props.data.LM_PAN_NO}
                  placeholder="Enter Pan No"
                  id="LM_PAN_NO"
                />
              </label>
            </div>

            <div className="col">
              <label htmlFor="LM_GST_NO">
                GST NO: <br />
                <input
                  type="text"
                  name="LM_GST_NO"
                  defaultValue={props.data.LM_GST_NO}
                  placeholder="Enter GST NO"
                  id="LM_GST_NO"
                />
              </label>
            </div>
            <div className="col">
              <label htmlFor="LM_AADHAR_NO">
                Aadhar No: <br />
                <input
                  type="text"
                  name="LM_AADHAR_NO"
                  defaultValue={props.data.LM_AADHAR_NO}
                  placeholder="Enter Aadhar No"
                  id="LM_AADHAR_NO"
                />
              </label>
            </div>
          </div>

          <input
            onClick={handleEdit}
            type="button"
            className="auth-btn"
            value="Update Table"
          />
        </div>
      </div>
    </div>
  );
};
