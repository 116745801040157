import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress style={{
        height: 20,
        width: 20,
        color: '#fff'
      }} />
    </Box>
  );
}