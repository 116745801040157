import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import PanelHead from "./components/PanelHead";
import { AiFillCamera } from "react-icons/ai";
import {
  addLedgerPhotos,
  addLedgerMaster,
  getLedgerMasters,
  getMastersId,
  getTypeId,
  getCountries,
  getStates,
  getCities,
} from "../../../js/formsDB";
import { ViewLedgerMaster } from "./View/ViewLedgerMaster";
import Compressor from "compressorjs";
import { getCookie } from "../../../js/cookies";

const AddDoctor = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [signaturePhoto, setSignaturePhoto] = useState(null);

  const [ledgertype, setLedgertype] = useState("Doctor");

  const [hideMain, setHideMain] = useState(false);

  const [masters, setMasters] = useState([]);

  const [admin, setAdmin] = useState({});

  const [specialties, setSpecialties] = useState([]);

  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [currentGrpName, setCurrentGroupName] = useState("");

  const [selectedSpecialty, setSelectedSpecialty] = useState({});

  const fetchCountries = async () => {
    var res = await getCountries();
    if(res.success){
      setCountries(res.countriesData);
    }
  }

  const fetchStates = async (countryId) => {
    var res = await getStates(countryId);
    if(res.success){
      setStates(res.statesData);
    }
  }

  const fetchCities = async (stateId) => {
    var res = await getCities(stateId);
    if(res.success){
      setCities(res.citiesData);
    }
  }

  const getElementValue = (id) => {
    const element = document.getElementById(id);
    return element ? element.value : "";
  };
  
  const handleSubmit = async () => {
    const check = ["groupID", "dname", "mobile", "email"];
    if (ledgertype === "Doctor") {
      check.push("regNo", "specialty", "qualification");
    }
  
    for (let i = 0; i < check.length; i++) {
      const elementValue = getElementValue(check[i]);
      console.log(elementValue, i);
      if (!elementValue) {
        alert("Please fill all mandatory fields");
        return;
      }
    }
  
    if (!profilePhoto) {
      alert("Please upload profile photo");
      return;
    }
  
    if (ledgertype === "Doctor" && !signaturePhoto) {
      alert("Please upload signature photo");
      return;
    }
  
    var is_active = document.querySelector("input[name=isActive]:checked").value;
  
    const data = {
      LM_REFERENCE_ID: parseInt(getElementValue("refID")) || "",
      LM_UNDER_GROUP_ID: getElementValue("groupID") || "",
      LM_MAIN_ID: parseInt(selectedSpecialty.TYPE_ID) || "",
      LM_UNDER_GROUP: getElementValue("groupName") || "",
      LM_NAME: getElementValue("dname") || "",
      LM_ALIAS: getElementValue("alias") || "",
      LM_ADDRESS: getElementValue("address1") || "",
      LM_CITY: getElementValue("city") || "",
      QUALIFICATION: getElementValue("qualification") || "",
      LM_STATE: getElementValue("state") || "",
      LM_COUNTRY: parseInt(getElementValue("country")) || "",
      LM_MOBILE: parseInt(getElementValue("mobile")) || "",
      LM_PHONE: getElementValue("phone") === "" ? "" : getElementValue("phone") || "",
      SPECIALITY: getElementValue("specialty") || "",
      REGISTRATION_NO: getElementValue("regNo") || "",
      LM_EMAIL_ID: getElementValue("email") || "",
      LM_PAN_NO: getElementValue("LM_PAN_NO") || "",
      LM_GST_NO: getElementValue("LM_GST_NO") || "",
      LM_AADHAR_NO: getElementValue("LM_AADHAR_NO") || "",
      profile: profilePhoto,
      IS_ACTIVE: is_active === "yes" ? true : false,
    };
  
    
    if (ledgertype === "Doctor") {
      data["signature"] = signaturePhoto;
    }
  
    console.log(data);
  
    var res = await addLedgerMaster(data);
  
    if (res.success) {
      alert(data.LM_UNDER_GROUP + " added successfully");
      // window.location.reload();
      document.getElementById("groupName").value = "0";
      document.getElementById("specialty").value = "0";
      setCurrentGroupName(0);
      await getMastersLength();
      var lis = [
        'dname',
        'alias',
        'regNo',
        'qualification',
        'address1',
        'email',
        'mobile',
        'phone',
        'LM_PAN_NO',
        'LM_GST_NO',
        'LM_AADHAR_NO',
      ];
      for (var i = 0; i < lis.length; i++) {
        document.getElementById(lis[i]).value = "";
      }
      document.getElementById("country").value = "91";
      fetchStates(91);
      fetchCities('Gujarat');
      // window.location.reload();
    } else {
      alert("Error while uploading profile info: " + res.message);
    }
  };
  
  


  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
  };

  useEffect(() => {
    var a = getCookie("admin");
    if (a !== "") {
      a = JSON.parse(a);
      setAdmin(a);
    }
    getMastersLength();
    fetchCountries();
    fetchStates(91);
    fetchCities('Gujarat');
  }, []);

  const getGroupId = (temp, name) => {
    var idArray = [];
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].LM_UNDER_GROUP === name) {
        idArray.push(temp[i].LM_UNDER_GROUP_ID);
      }
    }
    var maxId = 0;
    if(idArray.length > 0){
      maxId = Math.max(...idArray);
    }
    return maxId;
  };

  const getMastersLength = async () => {
    var res = await getLedgerMasters();

    var cm = [];
    var ct = 1;
    if (res.success) {
      setMasters(res.data);
      if (res.data.length > 0) {
        if(currentGrpName !== "" && currentGrpName !== 0){
          var c = getGroupId(res.data, res.data[0].LM_UNDER_GROUP);
          document.getElementById("groupID").value = c + 1;
        }
        else{
        document.getElementById("groupID").value = 0;
        }
        ct = res.maxid + 1;
        cm = res.data.filter((item) => item.LM_REFERENCE_ID === ct);
      }
      if (cm.length > 0) {
        ct = ct + 1;
      }
      document.getElementById("lm_reference_id").value = ct;
    } else {
      alert("Error: " + res.message);
    }
    var res2 = await getTypeId();

    if (res2.success) {
      var m = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Specialty" && item.IS_ACTIVE === true
      );
      setSpecialties(m);
      setSelectedSpecialty(m[0]);
    }
  };


  return (
    <div className="panel">
      <input
        id="profilePhoto"
        type="file"
        style={{
          display: "none",
        }}
        onChange={() => {
          const input = document.getElementById("profilePhoto");
          var upload_image = "";

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            upload_image = reader.result;
            
            document.getElementById("profile_photo_img").src = upload_image;
          });
          const image = input.files[0];
          const max_size = 1000000;
          const max_width = 2000;
          const max_height = 2000;
          const compress_image = new Compressor(image, {
            quality: 0.6,
            maxWidth: max_width,
            maxHeight: max_height,
            convertSize: max_size,
            success: (result) => {
              const file = new File([result], result.name, {
                type: "image/jpeg",
              });

              reader.readAsDataURL(file);
              setProfilePhoto(file);
            },
          });
        }}
      />

      <input
        id="signaturePhoto"
        type="file"
        style={{
          display: "none",
        }}
        onChange={() => {
          const input = document.getElementById("signaturePhoto");

          var upload_image = "";

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            upload_image = reader.result;
            document.getElementById("signature_photo_img").src = upload_image;
          });
          const image = input.files[0];
          const max_size = 1000000;
          const max_width = 2000;
          const max_height = 2000;
          const compress_image = new Compressor(image, {
            quality: 0.6,
            maxWidth: max_width,
            maxHeight: max_height,
            convertSize: max_size,
            success: (result) => {
              const file = new File([result], result.name, {
                type: "image/jpeg",
              });

              reader.readAsDataURL(file);
              setSignaturePhoto(file);
            },
          });
        }}
      />

      {!hideMain && admin.ROLE === 'admin' ? (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          {" "}
          <PanelHead title={"Add Ledger"} />
          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-2">
                  <label htmlFor="lm_reference_id">
                    Master ID: <br />
                    <input
                      readOnly={true}
                      type="text"
                      name="lm_reference_id"
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      placeholder="Ledger Master"
                      id="lm_reference_id"
                    />
                  </label>
                </div>
                <div className="col-2">
                  <label htmlFor="groupID">
                    Group ID: <br />
                    <input
                      type="text"
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      name="groupID"
                      placeholder="Enter Under Group ID"
                      id="groupID"
                    />
                  </label>
                </div>
                <div className="col-2">
                  <label htmlFor="refID">
                    Ref ID: <br />
                    <input
                      type="text"
                      readOnly={true}
                      defaultValue={Math.floor(Math.random() * 1000000) + 1}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      name="refID"
                      placeholder="Enter Reference ID"
                      id="refID"
                    />
                  </label>
                </div>
                <div className="col-2">
                  <div>
                    <h6 style={{ fontWeight: 600 }}>Is Active</h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isActive"
                          defaultChecked
                          id="isActive"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isActive"
                          id="isActive"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="col-4"
                >
                  <div className="row">
                    <div
                      style={{
                        marginRight: "50px",
                      }}
                      className="col"
                    >
                      <div
                        onClick={() => {
                          document.getElementById("profilePhoto").click();
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                        id="profile_photo"
                        className="profile_photo photo_col"
                      >
                        {profilePhoto ? (
                          <img
                            src={""}
                            alt="profile"
                            id="profile_photo_img"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <AiFillCamera
                              style={{
                                fontSize: "30px",
                                color: "#6d6d6d",
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Profile Photo
                      </p>
                    </div>

                    {ledgertype === "Doctor" && (
                      <div className="col">
                        <div
                          onClick={() => {
                            document.getElementById("signaturePhoto").click();
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                          id="signature_photo"
                          className="signature_photo photo_col"
                        >
                          {signaturePhoto ? (
                            <img
                              src={""}
                              alt="signature"
                              id="signature_photo_img"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <AiFillCamera
                                style={{
                                  fontSize: "30px",
                                  color: "#6d6d6d",
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Signature Photo
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label htmlFor="groupName">
                    Group Name: <br />
                    <select
                      onChange={(e) => {
                        if(e.target.value !== "0"){
                          setLedgertype(e.target.value);
                        setCurrentGroupName(e.target.value);
                        var c = getGroupId(masters, e.target.value);
                        document.getElementById("groupID").value = c + 1;
                        }
                        else{
                          setLedgertype("");
                        setCurrentGroupName(0);
                        document.getElementById("groupID").value = 0;
                        }
                      }}
                      name="groupName"
                      id="groupName"
                    >
                      <option value={0}>---SELECT---</option>
                      <option value="Doctor">Doctor</option>
                      <option value="Patient">Patient</option>
                    </select>
                  </label>
                </div>
                {
                  ledgertype === 'Doctor' && <>
                  <div className="col">
                  <label style={{
                    color: 'red'
                  }} htmlFor="specialty">
                    Specialty: <br />
                    <select onChange={(e)=>{
                      var t = specialties.filter((item)=>{
                        return item.TYPE_NAME === e.target.value
                      });
                      console.log(t[0])
                      setSelectedSpecialty(t[0]);
                    }} name="specialty" id="specialty">
                      {specialties.map((item, i) => {
                        return (
                          <option value={item.TYPE_NAME}>
                            {item.TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>

                <div className="col">
                  <label style={{color: 'red'}} htmlFor="regNo">
                    Reg No: <br />
                    <input
                      type="text"
                      name="regNo"
                      placeholder="Enter Registration No"
                      id="regNo"
                    />
                  </label>
                </div></>
                }
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="dname"
                  >
                    Name: <br />
                    <input
                      type="text"
                      name="dname"
                      placeholder="Enter Doctor Name"
                      id="dname"
                    />
                  </label>
                </div>
                <div className="col">
                  <label htmlFor="alias">
                    Alias: <br />
                    <input
                      type="text"
                      name="alias"
                      placeholder="Enter Alias"
                      id="alias"
                    />
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="qualification"
                  >
                    Qualification: <br />
                    <input
                      readOnly={ledgertype !== "Doctor"}
                      type="text"
                      name="qualification"
                      placeholder="Enter qualification"
                      id="qualification"
                    />
                  </label>
                </div>
              </div>
              <br />
              <label htmlFor="address1">
                Address: <br />
                <input
                  type="text"
                  name="address1"
                  placeholder="Enter Address"
                  id="address1"
                />
              </label>
              <br />
              <br />
              <div className="row">
                <div className="col">
                  <label htmlFor="country">
                    Country: <br />
                    <select onChange={(e)=>{
                        fetchStates(e.target.value);
                    }} name="country" id="country">
                     {
                        countries.map((item,i)=>{
                          return(
                            <option selected={parseInt(item.phonecode) === 91} value={parseInt(item.phonecode)}>{item.name}</option>
                          )
                        })
                     }
                    </select>
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="state">
                    State: <br />
                    <select onChange={(e)=>{
                        fetchCities(e.target.value);
                    }} name="state" id="state">
                      {
                        states.map((item,i)=>{
                          return(
                            <option  selected={item.id === 4030}  value={item.name}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="city">
                    City: <br />
                    <select name="city" id="city">
                        {
                          cities.map((item,i)=>{
                            return(
                              <option value={item.name}>{item.name}</option>
                            )
                          })
                        }
                    </select>
                  </label>
                </div>
              </div>

              {/* <div className="col">
              <label htmlFor="pinCode">
                PinCode: <br />
                <input
                  type="text"
                  name="pinCode"
                  placeholder="Enter PinCode"
                  id="pinCode"
                />
              </label>
            </div> */}
              <br />
              <div className="row">
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="email"
                  >
                    Email: <br />
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      id="email"
                    />
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="mobile"
                  >
                    Mobile: <br />
                    <input
                    maxLength={10}
                      type="number"
                      name="mobile"
                      placeholder="Enter Mobile"
                      id="mobile"
                    />
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="phone">
                    Phone: <br />
                    <input
                      type="text"
                      name="phone"
                      placeholder="Enter Phone"
                      id="phone"
                    />
                  </label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label htmlFor="LM_PAN_NO">
                    Pan No: <br />
                    <input
                      type="text"
                      name="LM_PAN_NO"
                      placeholder="Enter Pan No"
                      id="LM_PAN_NO"
                    />
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="LM_GST_NO">
                    GST NO: <br />
                    <input
                      type="text"
                      name="LM_GST_NO"
                      placeholder="Enter GST NO"
                      id="LM_GST_NO"
                    />
                  </label>
                </div>
                <div className="col">
                  <label htmlFor="LM_AADHAR_NO">
                    Aadhar No: <br />
                    <input
                      type="text"
                      name="LM_AADHAR_NO"
                      placeholder="Enter Aadhar No"
                      id="LM_AADHAR_NO"
                    />
                  </label>
                </div>
              </div>

              <input
                onClick={handleSubmit}
                type="button"
                className="auth-btn"
                value="Add Table"
              />
            </div>
          </div>
        </div>
      ) : null}
      <ViewLedgerMaster
        randT={Math.floor(Math.random() * 1000000) + 1}
        getM={getMastersLength}
        currentGrpName={currentGrpName}
        masters={masters}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default AddDoctor;
