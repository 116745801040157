import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../src/css/Auth.css';
import '../src/css/Managers.css';
import '../src/css/AdminPanel.css';
import RouterController from './components/RouterController';
import {SocketContext, socket} from '../src/provider/socket';

function App() {
  return (
    <div className="App">
     
     <SocketContext.Provider value={socket}>
     <RouterController />
      </SocketContext.Provider>

    </div>
  );
}

export default App;
