import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  deleteFreeAccessLedger,
  deleteSchemeMaster,
  deleteTypeMaster,
  getSchemes,
  getTypeId,
  updateFreeAcessLedger,
  updateSchemeMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { countries } from "../../../../js/countries";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export const ViewSpecialPeople = (props) => {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({});


  const changeMode = (mode, data) => {
    setData(data);
    setMode(mode);
  };

  if (mode === "view") {
    return <ViewSchemeMaster1 {...props} setMode={changeMode} />;
  } else if (mode === "edit") {
    return <EditSchemeMaster {...props} data={data} schemes={props.schemes} setMode={changeMode} schemeMaster={props.schemeMaster} companyNames={props.companyNames}/>;
  }
};

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button style={{
        height: 50,
        borderRadius: 0
      }} className="btn btn-success" onClick={handleClick}>
        Export
      </button>
    </div>
  );
};

export const ViewSchemeMaster1 = (props) => {
  const [masters, setMasters] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [searchType, setSearchType] = useState("SCHEME_NAME");

  const [randomT, setRandomT] = useState(0);

  const [done, setDone] = useState(false);

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
    },
    {
      dataField: "MOBILE_NUMBER",
      text: "MOBILE NUMBER",
      sort: true,
    },
    {
      dataField: "COMPANY_NAME",
      text: "COMPANY NAME",
      sort: true,
    },
    {
      dataField: "SCHEME_NAME",
      text: "SCHEME",
      sort: true,
    },
    {
      dataField: "NARRATION",
      text: "NARRATION",
      sort: true,
    },
    {
      dataField: "IS_EXPIRE",
      text: "IS EXPIRE",
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <BsFillPencilFill
            onClick={() => {
              props.setMode("edit", row);
              props.setHideMain(true);
            }}
            style={{
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "#2d55a8",
            }}
          /> */}
           <MdDelete
            onClick={async () => {
              var y = window.confirm(
                "Are you sure you want to delete this record?"
              );
              if (y) {
                var res = await deleteFreeAccessLedger(row.ID);
                if (res.success) {
                  alert("Deleted Successfully");
                  var t = masters.filter((m) => {
                    return m.ID !== row.ID;
                  });
                  setMasters(t);
                  var tb = backup.filter((m) => {
                    return m.ID !== row.ID;
                  });
                  setBackup(tb);
                  props.getM();
                } else {
                  alert(res.message);
                }
              }
            }}
            style={{
              marginLeft: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "red",
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.masters.length !== 0) {
      if (randomT !== props.randT) {
        setMasters(props.masters);
        setBackup(props.masters);
        setDone(true);
        setRandomT(props.randT);
      }
    }
  });
  return (
    <div
      style={{
        marginTop: 50,
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead disableMenu={true} title={"View Free Access"} />
      <div
        style={{
          padding: "15px",
        }}
      >
        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={masters}
            columns={columns}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: 50,
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"ID"}
                  >
                    <option value="ID">ID</option>
                    <option value="MOBILE_NUMBER">MOBILE NUMBER</option>
                    <option value="COMPANY_NAME">COMPANY NAME</option>
                    <option value="SCHEME_NAME">SCHEME ID</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = masters;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setMasters(l);
                      } else {
                        if (order === "asc") {
                          setMasters(backup);
                        } else {
                          setMasters(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search for Scheme Name"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                  <MyExportCSV {...props.csvProps} />
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          {/* <table id="customers">
            <thead>
              <tr>
                <th>ID</th>
                <th
                  style={{
                    width: 300,
                  }}
                >
                  NAME
                </th>
                <th>CODE</th>
                <th>COUNT</th>
                <th>AMOUNT</th>
                <th>NO OF CONS</th>
                <th>VALID DAYS</th>
                <th>ACTIVE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {masters.map((master) => {
                return (
                  <tr>
                    <td>{master.ID}</td>
                    <td>{master.SCHEME_NAME}</td>
                    <td>{master.COUNTRY_CODE}</td>
                    <td>{master.COUNT}</td>
                    <td>{master.AMOUNT}</td>
                    <td>{master.NO_OF_CONSULTATIONS}</td>
                    <td>{master.VALID_MONTH}</td>
                    <td>{master.IS_ACTIVE.toString()}</td>
                    <td>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <BsFillPencilFill
                          onClick={() => {
                            props.setMode("edit", master);
                            props.setHideMain(true);
                          }}
                          style={{
                            marginRight: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#2d55a8",
                          }}
                        />
                        <MdDelete
                          onClick={async () => {
                            var y = window.confirm(
                              "Are you sure you want to delete this record?"
                            );
                            if (y) {
                              var res = await deleteSchemeMaster(master.ID);
                              if (res.success) {
                                alert("Deleted Successfully");
                                var t = masters.filter((m) => {
                                  return m.ID !== master.ID;
                                });
                                setMasters(t);
                                props.getM();
                              } else {
                                alert("Error Occured");
                              }
                            }
                          }}
                          style={{
                            marginLeft: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

const EditSchemeMaster = (props) => {

  const [filteredCompanyNames, setFilteredCompanyNames] = useState([]);

  useEffect(()=>{
    console.log(props.data.SCHEME_NAME);
    props.schemes.forEach((item)=>{
      var splitted = item.split('-');
      if(parseInt(splitted[0]) === parseInt(props.data.SCHEME_NAME)){
        console.log(item);
        document.getElementById("schemeid").value = item;
      }
    });

    var filteredCompanyNames = [];

    if(props.data.COMPANY_NAME === ""){
      document.getElementById("companyname").value = "None";
    }

    props.companyNames.forEach((item)=>{
      if(item !== "None"){
        var parts = item.split(" - ");
        console.log("=====>"+parts)
        if(parts[1] === props.data.COMPANY_NAME){
          document.getElementById("companyname").value = item;
        }
      }
    });

    setFilteredCompanyNames(filteredCompanyNames);
  }, [])


  const handleEdit = async () => {
    var mobilenumber = document.getElementById("mobilenumber").value;
    var companyname = document.getElementById("companyname").value;
    var schemeid = document.getElementById("schemeid").value;
    var narration = document.getElementById("narration").value;
    

    if (mobilenumber === "") {
      alert("Enter Mobile Number");
      return;
    }


    if (schemeid === "") {
      alert("Select a Scheme");
      return;
    }

    schemeid = schemeid.split("-")[0];

    if(companyname === "None"){
      companyname = ""
    }
    else{
      companyname = companyname.split(" - ")[1];
    }

    var is_expire = document.querySelector(
      "input[name = isexpire]:checked"
    ).value;


    const data = {
      MOBILE_NUMBER: parseInt(mobilenumber),
      COMPANY_NAME: companyname,
      SCHEME_NAME: parseInt(schemeid),
      NARRATION: narration,
      IS_EXPIRE: is_expire === "yes"
    };

    console.log(data)

    var res = await updateFreeAcessLedger(data, props.data.ID);

    if (res.success) {
      alert("Updated Successfully");
      props.getM();
      props.setMode("view");
      props.setHideMain(false);
    } else {
      alert("Error Occured");
    }
  };



  return (
    <div
      style={{
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead
        showIcon={true}
        setHideMain={props.setHideMain}
        setMode={props.setMode}
        title={"Edit Special People"}
      />

      <div className="panel_cont">
        <div style={{ display: "flex" }} className="head"></div>
        <br />
        <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-2">
                  <label htmlFor="faID">
                    ID: <br />
                    <input
                    defaultValue={props.data.ID}
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="faID"
                      placeholder="ID"
                      id="faID"
                    />
                  </label>
                </div>

                <div className="col-4">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="mobilenumber"
                  >
                    Mobile Number: <br />
                    <input
                      type="text"
                      defaultValue={props.data.MOBILE_NUMBER}
                      name="mobilenumber"
                      placeholder="Mobile Number"
                      id="mobilenumber"
                    />
                  </label>
                </div>
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="schemeid"
                  >
                    Scheme: <br />
                    <select defaultValue={props.SCHEME_NAME} name="schemeid" id="schemeid">
                      {
                        props.schemes.map((item, i) => {
                          return (
                            <option value={item}>{item}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </label>
                </div>
               
              </div>


              <br />
              <div className="row">
              <div className="col">
                  <label
                    htmlFor="companyname"
                  >
                    Company Name: <br />
                    <select defaultValue={props.data.COMPANY_NAME}  name="companyname" id="companyname">
                      {
                        props.companyNames.map((item, i) => {
                          return (
                            <option value={item}>{item}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </label>
                </div>

                <div className="col">
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-10">
                  <label htmlFor="narration">
                    Narration: [seperation through dot(.)]<br />
                    <textarea
                      style={{
                        width: "100%",
                        height: 100,
                        border: '1px solid #212121',
                        borderRadius: 8,
                        padding: 5,
                        outline: 'none'
                      }}
                      defaultValue={props.data.NARRATION}
                      row="10"
                      type="text"
                      name="narration"
                      placeholder="Enter Narration"
                      id="narration"
                    />
                  </label>
                </div>

                <div style={{
                  textAlign: "center",
                  display: "flex",
                  position: "relative",
                  top: 10,
                  justifyContent: "center",
                }} className="col-2">
                  <div>
                    <h6>Is Expire: </h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isexpire"
                          defaultChecked={props.data.IS_EXPIRE}
                          id="isexpire"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                        defaultChecked={!props.data.IS_EXPIRE}
                          type="radio"
                          name="isexpire"
                          id="isexpire"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <input
                type="button"
                onClick={handleEdit}
                className="auth-btn"
                value="Update Table"
              />
            </div>
      </div>
    </div>
  );
};
