import React, { useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { deleteCookie, getCookie } from "../../js/cookies";
import { HiMenuAlt1 } from "react-icons/hi";
import { changePassword, logOutAdmin, fetchBalance } from "../../js/auth";
import { createCallQueue } from "../../js/formsDB";
import { SocketContext } from "../../provider/socket";

const AdminPanel = () => {
  var url = useLocation().pathname;
  var nav = useNavigate();
  const [page, setPage] = useState("rooms");
  const [done, setDone] = useState(false);

  const [sideOpen, setSideOpen] = useState(true);

  const socket = useContext(SocketContext);

  const [admin, setAdmin] = useState({});

  const [windowWidth, setWindowWidth] = useState(window.screen.width);
  const setWindowDimensions = () => {
    setWindowWidth(window.screen.width);
  };
  const [availableBalances, setAvailableBalance] = useState({});
  const getBalance = async () => {
    let res = await fetchBalance();
    if (res.balance.status === 'SUCCESS') {
    
    setAvailableBalance(res.balance.data); // update state with balance
    // console.log("Me innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (url === "/") {
      if (admin.ROLE === 'admin') {
        nav("/typeNameMaster");
        setPage("typeNameMaster");
      }
      else {
        nav("/schemeMaster");
        setPage("schemeMaster");
      }
    }

    setPage(url.replace("/", ""));

    if (windowWidth < 800) {
      handleSideBar();
    }
  }, [url]);

  useEffect(() => {
    socketInitAndAdminCheck();
    getBalance();
  }, []);

  const socketInitAndAdminCheck = async () => {
    var sadmin = getCookie("admin");
    if (sadmin !== "") {
      sadmin = JSON.parse(sadmin);
      setAdmin(sadmin);
      socket.emit("signin", {
        dm_id: sadmin.ADMIN_ID,
      });

      socket.emit("onHardCodedLeaveRoom", {});
      socket.emit("onHardCodeJoinRoom", {});
    } else {
      var res = await logOutAdmin();
      window.location.reload();
    }
  };

  const handleSideBar = () => {
    document.getElementById("side_bar").style.width = "0px";
  };

  return (
    <section id="admin_panel">
      <div id="side_bar">
        <div className="side_bar_header">
          <div className="side_bar_header_flex">
            <HiMenuAlt1
              style={{
                fontSize: "30px",
                cursor: "pointer",
                position: "relative",
                color: "#fff",
                top: 20,
                marginLeft: 10,
              }}
              onClick={() => {
                document.getElementById("side_bar").style.width = "0";
                document.getElementsByClassName("panel_bar")[0].style.width =
                  "100%";
                setSideOpen(false)
              }}
              className="icon"
            />

            <div className="profile_det">
              <h4>{admin.NAME}</h4>
              <div
                style={{ display: "flex", position: "relative", top: "3px" }}
                className="det_flex"
              >
                <span
                  onClick={async () => {
                    var res = await logOutAdmin();
                    if (res.success) {
                      alert("Logged out successfully");
                    } else {
                      alert("Error logging out");
                    }
                    window.location.reload();
                  }}
                >
                  Logout
                </span>

                <span
                  onClick={() => {
                    document.getElementById("change-p-i").style.display =
                      "block";
                  }}
                  style={{ paddingLeft: "8px" }}
                >
                  Change Password
                </span>
              </div>
            </div>
          </div>
          <br />
          <div id="change-p-i">
            <div
              onClick={() => {
                document.getElementById("change-p-i").style.display = "none";
              }}
              style={{
                textAlign: "right",
                color: "white",
                cursor: "pointer",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              X
            </div>
            <input
              type="password"
              name=""
              placeholder="Enter Old Password"
              id="old_p"
            />
            <br />
            <input
              type="password"
              name=""
              placeholder="Enter New Password"
              id="new_p"
            />
            <br />
            <input
              type="password"
              name=""
              placeholder="Confirm New Password"
              id="c_new_p"
            />
            <input onClick={async () => {
              var old_p = document.getElementById("old_p").value;
              var new_p = document.getElementById("new_p").value;
              var c_new_p = document.getElementById("c_new_p").value;
              if (new_p !== c_new_p) {
                alert("New Passwords do not match");
                return;
              }
              var admin = getCookie("admin");
              if (admin !== "") {
                admin = JSON.parse(admin);
                var res = await changePassword(admin.ADMIN_ID, {
                  PASSWORD: new_p,
                  OLD_PASSWORD: old_p
                });
                if (res.success) {
                  alert("Password changed successfully");
                  document.getElementById("change-p-i").style.display = "none";
                  document.getElementById("old_p").value = "";
                  document.getElementById("new_p").value = "";
                  document.getElementById("c_new_p").value = "";
                } else {
                  alert(res.error);
                }
              }
            }} type="button" className="c-p-btn" value="Change Password" />
            <br />
          </div>
        </div>

        <br />
        {/* {console.log(availableBalances.availableBalance)} */}
        <div className="side_bar_content">
          {
            <NavLink style={{ textDecoration: "none" }} to={"/"}>
            <div
              className={"list " + (page === "schemeMaster" ? "active" : "")}
            >
              <h5>Cashfree balance : {availableBalances?.availableBalance || "Loading..."}</h5>
            </div>
          </NavLink>
          }
          {
            admin.ROLE === "admin" && <>
              <NavLink style={{ textDecoration: "none" }} to={"/typeNameMaster"}>
                <div
                  className={"list " + (page === "typeNameMaster" ? "active" : "")}
                >
                  <h5>Type Name Master</h5>
                </div>
              </NavLink>

              <NavLink style={{ textDecoration: "none" }} to={"/typeMaster"}>
                <div className={"list " + (page === "typeMaster" ? "active" : "")}>
                  <h5>Type Master</h5>
                </div>
              </NavLink></>
          }

          <NavLink style={{ textDecoration: "none" }} to={"/freeAccess"}>
            <div
              className={"list " + (page === "freeAccess" ? "active" : "")}
            >
              <h5>Free Access</h5>
            </div>
          </NavLink>
          {
            admin.ROLE === "admin" && <NavLink style={{ textDecoration: "none" }} to={"/schemeMaster"}>
            <div
              className={"list " + (page === "schemeMaster" ? "active" : "")}
            >
              <h5>Scheme Master</h5>
            </div>
          </NavLink>
          }
           

          {
            admin.ROLE === "admin" && <NavLink style={{ textDecoration: "none" }} to={"/itemMaster"}>
              <div className={"list " + (page === "itemMaster" ? "active" : "")}>
                <h5>Item Master</h5>
              </div>
            </NavLink>
          }

          {
          
          admin.ROLE === "admin" &&  <NavLink style={{ textDecoration: "none" }} to={"/transactions"}>
            <div
              className={"list " + (page === "transactions" ? "active" : "")}
            >
              <h5>Transaction Logs</h5>
            </div>
          </NavLink>
          }

          <NavLink style={{ textDecoration: "none" }} to={"/ledgerMaster"}>
            <div
              className={"list " + (page === "ledgerMaster" ? "active" : "")}
            >
              <h5>{admin.ROLE === 'admin' ? 'Add' : 'Agent'} Ledger</h5>
            </div>
          </NavLink>

          

          <NavLink style={{ textDecoration: "none" }} to={"/callRegisters"}>
            <div
              className={"list " + (page === "callRegisters" ? "active" : "")}
            >
              <h5>Call Registers</h5>
            </div>
          </NavLink>

          {
          admin.ROLE === "admin" &&  <NavLink style={{ textDecoration: "none" }} to={"/agentReport"}>
            <div
              className={"list " + (page === "agentReport" ? "active" : "")}
            >
              <h5>Agent Reports</h5>
            </div>
          </NavLink>
          }

          {/* <NavLink
            onClick={() => {
              var d = new Date();
              var hour = d.getHours();
              var min = d.getMinutes();
              var sec = d.getSeconds();
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (min < 10) {
                min = "0" + min;
              }
              if (sec < 10) {
                sec = "0" + sec;
              }
              var time = hour + ":" + min + ":" + sec;
              var map = {
                lm_reference_id: Math.floor(Math.random() * 100),
                name: "Demo",
                cred: "+91" + Math.floor(Math.random() * 1000000000),
                dob: "2021-01-01",
                sex: "Male",
                photo:
                  "https://server.liveopd.in/uploads/1682590588202Hc3e61591078043e09dba7808a6be5d21n.jpg",
                queueStartTime: time,
                bloodGroup: "A+",
                maritalStatus: "Married",
                roomId: "room" + Math.floor(Math.random() * 100),
                refNo: Math.floor(Math.random() * 1000000000),
              };
              var res = createCallQueue(map);
              socket.emit("onCallQueue", map);
            }}
            style={{ textDecoration: "none" }}
            to={""}
          >
            <div className={"list " + (page === "dasda" ? "active" : "")}>
              <h5>Initiate a call queue</h5>
            </div>
          </NavLink> */}
        </div>
      </div>

      <div className="panel_bar">
        {
          !sideOpen && <HiMenuAlt1
            style={{
              fontSize: "30px",
              cursor: "pointer",
              position: "relative",
              color: "#212121",
              top: 20,
              marginLeft: 30,
            }}
            onClick={() => {
              document.getElementById("side_bar").style.width = "280px";
              document.getElementsByClassName("panel_bar")[0].style.width =
                "calc(100% - 280px)";
              setSideOpen(true)
            }}
            className="icon"
          />
        }
        <Outlet />
      </div>
    </section>
  );
};

export default AdminPanel;