import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminPanel from "../pages/admin/AdminPanel";
import Managers from "../pages/admin/panels/AddDoctor";
import ProtectedRoute from "./ProtectedRoute";
import SchemeMaster from "../pages/admin/panels/SchemeMaster";
import TypeNameMaster from "../pages/admin/panels/TypeNameMaster";
import ItemMaster from "../pages/admin/panels/ItemMaster";
import TypeMaster from "../pages/admin/panels/TypeMaster";
import AddDoctor from "../pages/admin/panels/AddDoctor";
import { ViewTypeNameMaster } from "../pages/admin/panels/View/ViewTypeName";
import { ViewTypeMaster } from "../pages/admin/panels/View/ViewTypeMaster";
import { ViewSchemeMaster } from "../pages/admin/panels/View/ViewSchemeMaster";
import { ViewItemMaster } from "../pages/admin/panels/View/ViewItemMaster";
import { ViewCallRegister } from "../pages/admin/panels/View/ViewCallRegister";
import { ViewAgentReport } from "../pages/admin/panels/View/ViewAgentReport";
import SpecialPeople from "../pages/admin/panels/SpecialPeople";
import { ViewTransactionLogs } from "../pages/admin/panels/View/ViewTransactionLogs";


const RouterController = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ProtectedRoute><AdminPanel/></ProtectedRoute>}>
                    <Route path="/managers"  element={<Managers />} />
                    <Route path="/schemeMaster"  element={<SchemeMaster />} />
                    <Route path="/typeMaster"  element={<TypeMaster />} />
                    <Route path="/typeNameMaster"  element={<TypeNameMaster />} />
                    <Route path="/itemMaster"  element={<ItemMaster />} />
                    <Route path="/ledgerMaster"  element={<AddDoctor />} />
                    <Route path="/viewTypeNameMaster"  element={<ViewTypeNameMaster />} />
                    <Route path="/viewTypeMaster"  element={<ViewTypeMaster />} />
                    <Route path="/viewSchemeMaster"  element={<ViewSchemeMaster />} />
                    <Route path="/viewItemMaster"  element={<ViewItemMaster />} />
                    <Route path="/callRegisters"  element={<ViewCallRegister />} />
                    <Route path="/agentReport"  element={<ViewAgentReport />} />
                    <Route path="/freeAccess"  element={<SpecialPeople />} />
                    <Route path="/transactions"  element={<ViewTransactionLogs />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default RouterController;