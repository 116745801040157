import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import PanelHead from "./components/PanelHead";
import { postTypeNameMaster, getMastersId } from "../../../js/formsDB";
import { ViewTypeNameMaster } from "./View/ViewTypeName";


const TypeNameMaster = () => {
  const [hideMain, setHideMain] = useState(false);

  const [loading, setLoading] = useState(false);

  const [masters, setMasters] = useState([]);

  useEffect(() => {
    getMastersLength();
  }, []);

  const getMastersLength = async () => {
    var res = await getMastersId();

    if (res.success) {
      setMasters(res.typeNameMaster);

      var ct = res.maxid + 1;
      var cm = res.typeNameMaster.filter((item) => item.MASTER_ID === ct);
      if (cm.length > 0) {
        ct = ct + 1;
      }
      document.getElementById("mid").value = ct;
    } else {
      alert("Error: " + res.message);
    }
  };

  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
  };

  const handleSubmit = async () => {
    var mname = document.getElementById("mname").value;
    if (mname === "") {
      alert("Enter Type Name");
      return;
    }
    setLoading(true);
    var is_active = document.querySelector(
      "input[name = isactive]:checked"
    ).value;
    var is_group = document.querySelector(
      "input[name = isgroup]:checked"
    ).value;
    var remarks_display = document.querySelector(
      "input[name = remarksDisplay]:checked"
    ).value;
    const data = {
      MASTER_TYPE_NAME: mname,
      IS_ACTIVE: is_active === "yes",
      MASTER_IS_GROUP: is_group === "yes",
      MASTER_REMARKS_DISPLAY: remarks_display === "yes",
    };

    var res = await postTypeNameMaster(data);

    if (res.success) {
      alert("Type Name Master Added Successfully");
      await getMastersLength();
    } else {
      alert("Error: " + res.message);
    }
    setLoading(false);
  };

  return (
    <div className="panel">
      {!hideMain && (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          <PanelHead title={"Type Name Master"} />{" "}
          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-4">
                  <h6>Master ID: </h6>
                  <label htmlFor="mid">
                    <input
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="mid"
                      placeholder="Master ID"
                      id="mid"
                    />
                  </label>
                </div>

                <div className="col-8">
                  <h6
                    style={{
                      color: "red",
                    }}
                  >
                    Type Name:{" "}
                  </h6>
                  <label htmlFor="name">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Type Name"
                      id="mname"
                    />
                  </label>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col">
                  <h6>Is Active: </h6>
                  <div style={{ marginTop: "10px", display: "flex" }}>
                    <label
                      style={{
                        width: "fit-content",
                        display: "flex",
                        marginRight: "20px",
                      }}
                    >
                      <input
                        type="radio"
                        name="isactive"
                        defaultChecked
                        id="isactive"
                        value={"yes"}
                      />
                      <span style={{ marginLeft: "3px" }}>Yes</span>
                    </label>

                    <label
                      style={{
                        width: "fit-content",
                        display: "flex",
                      }}
                    >
                      <input
                        type="radio"
                        name="isactive"
                        id="isactive"
                        value={"no"}
                      />
                      <span style={{ marginLeft: "3px" }}>No</span>
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  className="col"
                >
                  <div>
                    <h6>Is Group</h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isgroup"
                          id="isgroup"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isgroup"
                          defaultChecked
                          id="isgroup"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                  className="col"
                >
                  <div>
                    <h6>Remarks Display</h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="remarksDisplay"
                          id="remarksDisplay"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="remarksDisplay"
                          defaultChecked
                          id="remarksDisplay"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <input
                onClick={handleSubmit}
                type="button"
                className="auth-btn"
                value="Add Table"
              />
            </div>
          </div>
        </div>
      )}

      <ViewTypeNameMaster
      randT={Math.floor(Math.random() * 100000)}
        masters={masters}
        getM={getMastersLength}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default TypeNameMaster;
