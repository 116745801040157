import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
    deleteItemMaster,
    deleteSchemeMaster,
    getAgentReports,
    getItemsId,
    getSchemes,
    getTypeId,
    updateItemMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";


const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div>
            <button style={{
                height: 50,
                borderRadius: 0
            }} className="btn btn-success" onClick={handleClick}>
                Export
            </button>
        </div>
    );
};

export const ViewAgentReport = (props) => {
    const [masters, setMasters] = useState([]);

    const [backup, setBackup] = useState([]);

    const [order, setOrder] = useState("asc");

    const [prevGroup, setPrevGroup] = useState("");

    const [searchType, setSearchType] = useState("Name");

    const [startDate, setStartDate] = useState("");

    const [endDate, setEndDate] = useState("");

    const [randomT, setRandomT] = useState(0);

    const [done, setDone] = useState(false);

    const [searchIsAgent, setSearchIsAgent] = useState(false);


    const getDate = async () => {
        var d = new Date();
        var day = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        if (day.toString().length === 1) day = "0" + day;
        if (month.toString().length === 1) month = "0" + month;
        d = year + "-" + month + "-" + day;
        setStartDate(d);
        setEndDate(d);
        return d;
    };

    const filterUsingDate = async (start, endD) => {
        var s_start = start;
        var s_end = endD;
        console.log(s_start);
        console.log(s_end);
        var sd = new Date(s_start);
        var ed = new Date(s_end);
        // add 1 more day to ed
        ed.setDate(ed.getDate() + 1);
        //in yyyy-mm-dd format
        sd = sd.toISOString().split("T")[0];
        ed = ed.toISOString().split("T")[0];
        console.log(sd);
        console.log(ed);
        // var t = backup;
        // console.log(t);
        // t = t.filter((d) => {
        //     var time = new Date(d.Transaction_date).getTime();
        //     console.log(time);
        //     return sd < time && time < ed;
        // });
        // console.log('========.');
        // console.log(t);
        // setMasters(t);
        var res = await getAgentReports(sd, ed);
        if (res.success) {
            var data = res.data;
            setMasters(data);
            setBackup(data);
        }
    };



    const columns = [
        {
            dataField: "Sr",
            text: "SI",
            sort: true,
        },
        {
            dataField: "lm_reference_id",
            text: "Ref Id",
            sort: true,
        },
        {
            dataField: "Name",
            text: "NAME",
            sort: true,
        },
        {
            dataField: "mobile",
            text: "Mobile",
            sort: true,
        },
        {
            dataField: "State",
            text: "State",
            sort: true,
        },
        {
            dataField: "City",
            text: "City",
            sort: true,
        },
        {
            dataField: "Transaction_id",
            text: "Transaction ID",
            sort: true,
        },
        {
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                var d = new Date(row.Transaction_date);
                var day = d.getDate();
                var month = d.getMonth() + 1;
                var year = d.getFullYear();
                if (day.toString().length === 1) day = "0" + day;
                if (month.toString().length === 1) month = "0" + month;
                d = day + "-" + month + "-" + year;
                return d;
            }
        },
        {
            dataField: "Scheme_Name",
            text: "Scheme Name",
            sort: true,
        },
        {
            dataField: "Scheme_Amount",
            text: "Scheme Amount",
            sort: true,
        },
        {
            dataField: "Agent_Name",
            text: "Agent Name",
            sort: true,
        },
        {
            text: "Sharing Percent",
            sort: true,
            formatter: (cell, row) => {
                if(row.Is_Referred_From_Agent){
                    return row.Sharing_Percent
                }
                else{
                    return "0.00"
                }
            }
        },
        {
            dataField: "Sharing_Amount",
            text: "Sharing Amount",
            sort: true,
            formatter: (cell, row) => {
                if(row.Is_Referred_From_Agent){
                    return row.Sharing_Amount
                }
                else{
                    return "0.00"
                }
            }
        },
        {
            dataField: "Agent_Total",
            text: "Agent Total",
            sort: true,
            formatter: (cell, row) => {
                if(row.Is_Referred_From_Agent){
                    return row.Agent_Total
                }
                else{
                    return "0.00"
                }
            }
        },

    ];

    useEffect(() => {
        getAllReports();
        getDate();
    }, []);

    const getAllReports = async () => {
        var d = new Date();
        // in yyyy-mm-dd format
        d = d.toISOString().split("T")[0];
        var res = await getAgentReports(d, d);
        if (res.success) {
            var data = res.data;
            var s_start = await getDate();
            var start = new Date(s_start).getTime();
            var ed = new Date(s_start);
            // add 1 more day to ed
            ed.setDate(ed.getDate() + 1);
            ed = ed.getTime();
            var t_data = data.filter((d) => {
                var time = new Date(d.Transaction_date).getTime();
                return start < time && time < ed;
            });
            console.log('===========>');
            console.log(data)
            setMasters(t_data);
            setBackup(data);
        }
    }
    return (
        <div
            style={{
                margin: 10,
                border: "3px solid #212121",
            }}
            className="panels"
        >
            <PanelHead disableMenu={true} title={"View Agent Report"} />
            <div
                style={{
                    padding: "15px",
                }}
            >
                <div className="panel_cont">

                    <ToolkitProvider
                        keyField="id"
                        data={masters}
                        columns={columns}
                        exportCSV
                    >
                        {(props) => (
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: 10,
                                            position: "relative",
                                            fontWeight: 600,
                                        }}
                                    >
                                        FROM -{" "}
                                    </span>
                                    <input
                                        id="startDate"
                                        style={{
                                            padding: 5,
                                        }}
                                        type="date"
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                            filterUsingDate(
                                                e.target.value,
                                                document.getElementById("endDate").value
                                            );
                                        }}
                                        value={startDate}
                                    />
                                    <span
                                        style={{
                                            marginRight: 10,
                                            marginLeft: 10,
                                            position: "relative",
                                            fontWeight: 600,
                                        }}
                                    >
                                        TO -{" "}
                                    </span>
                                    <input
                                        id="endDate"
                                        style={{
                                            padding: 5,
                                        }}
                                        type="date"
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                            filterUsingDate(
                                                document.getElementById("startDate").value,
                                                e.target.value
                                            );
                                        }}
                                        value={endDate}
                                    />

                                    <label style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }} htmlFor="is_agent">
                                    <input onChange={(val)=>{
                                        setSearchIsAgent(!searchIsAgent);
                                        if(val.target.checked){
                                            var l = backup;
                                            l = l.filter((d) => {
                                                var time = new Date(d.Transaction_date).getTime();
                                                console.log(time);
                                                var sd = new Date(startDate).getTime();
                                                var ed = new Date(endDate);
                                                // add 1 more day to ed
                                                ed.setDate(ed.getDate() + 1);
                                                ed = ed.getTime();
                                                return sd < time && time < ed;
                                            });
                                            l = l.filter((item) => {
                                                if (item.Is_Referred_From_Agent) {
                                                    return item
                                                }
                                            });
                                            setMasters(l);
                                        }
                                        else{
                                            var l = backup;
                                            l = l.filter((d) => {
                                                var time = new Date(d.Transaction_date).getTime();
                                                console.log(time);
                                                var sd = new Date(startDate).getTime();
                                                var ed = new Date(endDate);
                                                // add 1 more day to ed
                                                ed.setDate(ed.getDate() + 1);
                                                ed = ed.getTime();
                                                return sd < time && time < ed;
                                            });
                                            l = l.filter((item) => {
                                                if (!item.Is_Referred_From_Agent) {
                                                    return item
                                                }
                                            });
                                            setMasters(l);
                                        }
                                    }} value={searchIsAgent} style={{
                                        height: 20,
                                        marginLeft: 20,
                                        width: 20,
                                    }} type="checkbox" name="" id="" />
                                    <span style={{
                                        marginLeft: 5,
                                        fontWeight: 600,
                                    }}>Agent</span>
                                    </label>
                                </div>

                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        height: 50,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <select
                                        onChange={(e) => {
                                            setSearchType(e.target.value);
                                        }}
                                        defaultValue={"Name"}
                                    >
                                        <option value="Name">Name</option>
                                        <option value="lm">Mobile</option>
                                        <option value="State">State</option>
                                        <option value="City">City</option>
                                        <option value="Transaction_id">Transaction ID</option>
                                        <option value="Agent_Name">Agent Name</option>
                                        <option value="Scheme_Name">Scheme Name</option>
                                        <option value="Scheme_Amount">Scheme Amount</option>
                                        <option value="Sharing_Percent">Sharing Percent</option>
                                        <option value="Sharing_Amount">Sharing Amount</option>
                                        <option value="Agent_Total">Agent Total</option>
                                    </select>
                                    <input
                                        onChange={(e) => {
                                            if (e.target.value !== "") {
                                                var l = masters;
                                                l = l.filter((item) => {
                                                    if (item[searchType] !== null) {
                                                        return item[searchType]
                                                            .toString()
                                                            .toLowerCase()
                                                            .includes(e.target.value.toLowerCase())
                                                    }

                                                });

                                                setMasters(l);
                                            } else {
                                                if (order === "asc") {
                                                    setMasters(backup);
                                                } else {
                                                    setMasters(backup.reverse());
                                                }
                                            }
                                        }}
                                        placeholder="Search Agent Report"
                                        style={{
                                            width: "100%",
                                            height: 50,
                                            border: "1px solid black",
                                            outline: "none",
                                            padding: "5px",
                                        }}
                                        type="text"
                                    />

                                    <MyExportCSV {...props.csvProps} />
                                </div>

                                <hr />
                                <BootstrapTable
                                    hover
                                    striped
                                    condensed
                                    headerWrapperClasses="foo"
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
            </div>
        </div>
    );
};