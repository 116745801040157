import React, { useEffect, useState } from "react";
import PanelHead from "./components/PanelHead";
import { getItemsId, getTypeId, postItemMasterData } from "../../../js/formsDB";
import { ViewItemMaster } from "./View/ViewItemMaster";

const ItemMaster = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [genericName, setGenericName] = useState([]);
  const [dosageInterval, setDosageInterval] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);

  const [itemMasters, setItemMasters] = useState([]);

  const [done, setDone] = useState(false);

  const [currentGroupName, setCurrentGroupName] = useState("");

  const [hideMain, setHideMain] = useState(false);

  useEffect(() => {
    if (!done) {
      getMastersLength();
      setDone(true);
    }
  });

  const getGroupId = (temp, name) => {
    var idArray = [];
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].DEPARTMENT === name) {
        idArray.push(temp[i].ITEM_GRP_ID);
      }
    }
    var maxId = 0;
    if(idArray.length > 0){
      maxId = Math.max(...idArray);
    }
    return maxId;
  };

  const changeHideMain = (val) => {
    setHideMain(val);
    getMastersLength();
  };

  const getAllData = async (itemMaster) => {
    var res2 = await getTypeId();
    if (res2.success) {
      var m = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Manufacturer" && item.IS_ACTIVE === true
      );
      var g = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Generic Name" && item.IS_ACTIVE === true
      );
      var d = res2.typeMaster.filter(
        (item) =>
          item.MASTER_NAME === "Dosage Interval" && item.IS_ACTIVE === true
      );
      var d1 = res2.typeMaster.filter(
        (item) =>
          item.MASTER_NAME === "Department" && item.IS_ACTIVE === true
      );
      var i = res2.typeMaster.filter(
        (item) => item.MASTER_NAME === "Item Group" && item.IS_ACTIVE === true
      );
      setManufacturers(m);
      setGenericName(g);
      setDosageInterval(d);
      setDepartments(d1);
      setItemGroups(i);
    }
  };

  const getMastersLength = async () => {
    var res = await getItemsId();

    if (res.success) {
      var ct = res.maxid + 1;
      var cm = res.itemMaster.filter((item) => item.MASTER_ID === ct);
      if (cm.length > 0) {
        ct = ct + 1;
      }
      document.getElementById("itemID").value = ct;
      setItemMasters(res.itemMaster);
      if(currentGroupName !== "" && currentGroupName !== 0){
        var c = getGroupId(res.itemMaster, res.itemMaster[0].DEPARTMENT);
        document.getElementById("itemGroupID").value = c + 1;
      }
      else{
      document.getElementById("itemGroupID").value = 0;
      }
    }

    await getAllData(res.itemMaster);
  };

  const handleSubmit = async () => {
    var itemGroupID = document.getElementById("itemGroupID").value;
    var itemName = document.getElementById("itemName").value;

    if (itemName === "") {
      alert("Enter Item Name");
      return;
    }

    if(document.getElementById("department").value === "0"){
      alert("Select a department");
      return;
    }

    var is_active = document.querySelector(
      "input[name = isactive]:checked"
    ).value;

    const data = {
      ITEM_NAME: itemName,
      ITEM_GRP: document.getElementById("itemGroup").value,
      ITEM_GRP_ID: parseInt(itemGroupID),
      DEPARTMENT: document.getElementById("department").value,
      GENERIC_NAME: document.getElementById("genericName").value,
      MANUFACTURER: document.getElementById("manufacturer").value,
      DOSAGE_INTERVAL: document.getElementById("dosageInterval").value,
      IS_ACTIVE: is_active === "yes",
    };

    var res = await postItemMasterData(data);

    if (res.success) {
      alert("Item Master Added Successfully");
      document.getElementById("itemName").value = "";
      document.getElementById("department").value = "0";
      setCurrentGroupName(0);
      await getMastersLength();
    } else {
      alert("Error: " + res.message);
    }
  };

  return (
    <div className="panel">
      {!hideMain && (
        <div
          style={{
            border: "3px solid #212121",
          }}
        >
          <PanelHead title={"ItemMaster"} />

          <div className="panel_cont">
            <div style={{ display: "flex" }} className="head"></div>
            <br />
            <div
              style={{
                padding: "15px",
              }}
              className="edit-form"
            >
              <div className="row">
                <div className="col-2">
                  <label htmlFor="itemID">
                    Item ID: <br />
                    <input
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      type="text"
                      name="itemID"
                      placeholder="Item ID"
                      id="itemID"
                    />
                  </label>
                </div>
                <div className="col-2">
                  <label htmlFor="itemGroupID">
                    Dept ID: <br />
                    <input
                      type="text"
                      readOnly={true}
                      style={{
                        backgroundColor: "#e0e0e0",
                      }}
                      name="itemGroupID"
                      placeholder="Enter Item Group ID"
                      id="itemGroupID"
                    />
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="department">
                    Department: <br />
                    <select
                      onChange={(e) => {
                        if(e.target.value !== "0"){
                          setCurrentGroupName(e.target.value);
                        var c = getGroupId(itemMasters, e.target.value);
                        document.getElementById("itemGroupID").value = c + 1;
                        }
                        else{
                          setCurrentGroupName(0);
                          document.getElementById("itemGroupID").value = 0;
                        }
                      }}
                      name="department"
                      id="department"
                    >
                      <option value={0}>---SELECT---</option>
                      {
                        departments.map((item) => {
                          return (
                            <option value={item.TYPE_NAME}>
                              {item.TYPE_NAME}
                            </option>
                          );
                        })
                      }
                    </select>
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="itemGroup">
                    Item Group: <br />
                    <select
                      onChange={(e) => {
                       
                      }}
                      name="itemGroup"
                      id="itemGroup"
                    >
                      {itemGroups.map((item) => {
                        return (
                          <option value={item.TYPE_NAME}>
                            {item.TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: 10,
                  }}
                  className="col-3"
                >
                  <div>
                    <h6>Is Active: </h6>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <input
                          type="radio"
                          name="isactive"
                          defaultChecked
                          id="isactive"
                          value={"yes"}
                        />
                        <span style={{ marginLeft: "3px" }}>Yes</span>
                      </label>

                      <label
                        style={{
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          name="isactive"
                          id="isactive"
                          value={"no"}
                        />
                        <span style={{ marginLeft: "3px" }}>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col">
                  <label
                    style={{
                      color: "red",
                    }}
                    htmlFor="itemName"
                  >
                    Item Name: <br />
                    <input
                      type="text"
                      name="itemName"
                      placeholder="Enter Item Name"
                      id="itemName"
                    />
                  </label>
                </div>

                <div className="col">
                  <label htmlFor="genericName">
                    Generic Name: <br />
                    <select name="genericName" id="genericName">
                      {genericName.map((item) => {
                        return (
                          <option value={item.TYPE_NAME}>
                            {item.TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col">
                  <label htmlFor="manufacturer">
                    Manufacturer: <br />
                    <select name="manufacturer" id="manufacturer">
                      {manufacturers.map((item) => {
                        return (
                          <option value={item.TYPE_NAME}>
                            {item.TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="col">
                  <label htmlFor="dosageInterval">
                    Dosage Interval: <br />
                    <select name="dosageInterval" id="dosageInterval">
                      {dosageInterval.map((item) => {
                        return (
                          <option value={item.TYPE_NAME}>
                            {item.TYPE_NAME}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>

              <input
                onClick={handleSubmit}
                type="button"
                className="auth-btn"
                value="Add Table"
              />
            </div>
          </div>
        </div>
      )}

      <ViewItemMaster
        getM={getMastersLength}
        randT={Math.floor(Math.random() * 100000)}
        masters={itemMasters}
        currentGroupName={currentGroupName}
        setHideMain={changeHideMain}
      />
    </div>
  );
};

export default ItemMaster;
