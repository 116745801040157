import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  deleteSchemeMaster,
  deleteTypeMaster,
  getSchemes,
  getTypeId,
  updateSchemeMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { countries } from "../../../../js/countries";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export const ViewSchemeMaster = (props) => {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({});

  const changeMode = (mode, data) => {
    setData(data);
    setMode(mode);
  };

  if (mode === "view") {
    return <ViewSchemeMaster1 {...props} setMode={changeMode} />;
  } else if (mode === "edit") {
    return <EditSchemeMaster {...props} data={data} setMode={changeMode} />;
  }
};

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button style={{
        height: 50,
        borderRadius: 0
      }} className="btn btn-success" onClick={handleClick}>
        Export
      </button>
    </div>
  );
};

export const ViewSchemeMaster1 = (props) => {
  const [masters, setMasters] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [searchType, setSearchType] = useState("SCHEME_NAME");

  const [randomT, setRandomT] = useState(0);

  const [done, setDone] = useState(false);

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
    },
    {
      dataField: "SCHEME_NAME",
      text: "NAME",
      sort: true,
    },
    {
      dataField: "COUNTRY_CODE",
      text: "CODE",
      sort: true,
      hidden: true
    },
    {
      dataField: "TYPE_OF_SUBSCRIPTION",
      text: "Type",
      sort: true,
    },
    {
      dataField: "COUNT",
      text: "COUNT",
      sort: true,
    },
    {
      dataField: "AMOUNT",
      text: "AMOUNT",
      sort: true,
    },
    {
      dataField: "NO_OF_CONSULTATIONS",
      text: "NO OF CONS",
      sort: true,
    },
    {
      dataField: "VALID_DAYS",
      text: "VALID DAYS",
      sort: true,
    },
    {
      dataField: "PERCENTAGE",
      text: "Ref.%",
      sort: true,
    },
    {
      dataField: "NARRATION",
      text: "NARRATION",
      sort: true,
      hidden: true
    },
    {
      dataField: "IS_ACTIVE",
      text: "ACTIVE",
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BsFillPencilFill
            onClick={() => {
              props.setMode("edit", row);
              props.setHideMain(true);
            }}
            style={{
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "#2d55a8",
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.masters.length !== 0) {
      if (randomT !== props.randT) {
        setMasters(props.masters);
        setBackup(props.masters);
        setDone(true);
        setRandomT(props.randT);
      }
    }
  });
  return (
    <div
      style={{
        marginTop: 50,
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead disableMenu={true} title={"View Scheme Master"} />
      <div
        style={{
          padding: "15px",
        }}
      >
        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={masters}
            columns={columns}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: 50,
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"SCHEME_NAME"}
                  >
                    <option value="ID">ID</option>
                    <option value="SCHEME_NAME">SCHEME NAME</option>
                    <option value="COUNTRY_CODE">COUNTRY CODE</option>
                    <option value="COUNT">COUNT</option>
                    <option value="AMOUNT">AMOUNT</option>
                    <option value="NO_OF_CONSULTATIONS">NO OF CONS</option>
                    <option value="VALID_MONTH">VALID DAYS</option>
                    <option value="IS_ACTIVE">ACTIVE</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = masters;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setMasters(l);
                      } else {
                        if (order === "asc") {
                          setMasters(backup);
                        } else {
                          setMasters(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search for Scheme Name"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                  <MyExportCSV {...props.csvProps} />
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          {/* <table id="customers">
            <thead>
              <tr>
                <th>ID</th>
                <th
                  style={{
                    width: 300,
                  }}
                >
                  NAME
                </th>
                <th>CODE</th>
                <th>COUNT</th>
                <th>AMOUNT</th>
                <th>NO OF CONS</th>
                <th>VALID DAYS</th>
                <th>ACTIVE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {masters.map((master) => {
                return (
                  <tr>
                    <td>{master.ID}</td>
                    <td>{master.SCHEME_NAME}</td>
                    <td>{master.COUNTRY_CODE}</td>
                    <td>{master.COUNT}</td>
                    <td>{master.AMOUNT}</td>
                    <td>{master.NO_OF_CONSULTATIONS}</td>
                    <td>{master.VALID_MONTH}</td>
                    <td>{master.IS_ACTIVE.toString()}</td>
                    <td>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <BsFillPencilFill
                          onClick={() => {
                            props.setMode("edit", master);
                            props.setHideMain(true);
                          }}
                          style={{
                            marginRight: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#2d55a8",
                          }}
                        />
                        <MdDelete
                          onClick={async () => {
                            var y = window.confirm(
                              "Are you sure you want to delete this record?"
                            );
                            if (y) {
                              var res = await deleteSchemeMaster(master.ID);
                              if (res.success) {
                                alert("Deleted Successfully");
                                var t = masters.filter((m) => {
                                  return m.ID !== master.ID;
                                });
                                setMasters(t);
                                props.getM();
                              } else {
                                alert("Error Occured");
                              }
                            }
                          }}
                          style={{
                            marginLeft: "10px",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

const EditSchemeMaster = (props) => {

  const [types, setTypes] = useState([]);


  const handleEdit = async () => {
    var sname = document.getElementById("schemeName").value;
    var count = document.getElementById("count").value;
    var amount = document.getElementById("amount").value;
    var noOfConsultations = document.getElementById("noofconsulation").value;
    var validDays = document.getElementById("valid_month").value;
    var narration = document.getElementById("narration").value;
    var is_active = document.querySelector(
      "input[name = isactive]:checked"
    ).value;

    if (
      sname === "" ||
      count === "" ||
      amount === "" ||
      noOfConsultations === "" ||
      validDays === ""
    ) {
      alert("Please fill all the fields");
      return;
    }

    var data = {
      SCHEME_NAME: sname,
      COUNT: count,
      AMOUNT: amount,
      NO_OF_CONSULTATIONS: noOfConsultations,
      VALID_DAYS: validDays,
      NARRATION: narration,
      IS_ACTIVE: is_active === "yes" ? true : false,
      COUNTRY_CODE: document.getElementById("countryCode").value,
      PERCENTAGE: parseInt(document.getElementById("percentage").value),
      TYPE_OF_SUBSCRIPTION: document.getElementById("schemeType").value
    };

    var res = await updateSchemeMaster(data, props.data.ID);

    if (res.success) {
      alert("Updated Successfully");
      props.getM();
      props.setMode("view");
      props.setHideMain(false);
    } else {
      alert("Error Occured");
    }
  };

  const getSchemeType = async () => {
    var res2 = await getTypeId();
    if (res2.success) {
      console.log(res2)
      var typeMasters = res2.typeMaster;
      typeMasters = typeMasters.filter((item) => item.IS_ACTIVE === true);
      typeMasters = typeMasters.filter((item) => item.MASTER_NAME === "Scheme Type");
      var ty = [];
      typeMasters.forEach((item) => {
        ty.push(item.TYPE_NAME);
      }
      );
      setTypes(ty);
    }
  }


  useEffect(() => {
    getSchemeType();
  })

  return (
    <div
      style={{
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead
        showIcon={true}
        setHideMain={props.setHideMain}
        setMode={props.setMode}
        title={"Edit Scheme Master"}
      />

      <div className="panel_cont">
        <div style={{ display: "flex" }} className="head"></div>
        <br />
        <div
          style={{
            padding: 15,
          }}
          className="edit-form"
        >
          <div className="row">
            <div className="col-2">
              <label htmlFor="e_schemeID">
                Scheme ID: <br />
                <input
                  readOnly={true}
                  defaultValue={props.data.ID}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  type="text"
                  name="e_schemeID"
                  placeholder="Scheme ID"
                  id="e_schemeID"
                />
              </label>
            </div>

            <div className="col-7">
              <label style={{
                color: 'red'
              }} htmlFor="schemeName">
                Scheme Name: <br />
                <input
                  type="text"
                  defaultValue={props.data.SCHEME_NAME}
                  name="schemeName"
                  placeholder="Scheme Name"
                  id="schemeName"
                />
              </label>
            </div>

            <div className="col">
              <label style={{
                color: 'red'
              }} htmlFor="schemeType">
                Type: <br />
                <select name="schemeType" id="schemeType">
                  {
                    types.map((item, i) => {
                      return (
                        <option
                          selected={item === props.data.TYPE_OF_SUBSCRIPTION}
                          value={item}
                        >
                          {item}
                        </option>
                      );
                    }
                    )
                  }
                </select>
              </label>
            </div>

          </div>
          <br />
          <div className="row">
            <div className="col-lg-2 col-sm-12">
              <label style={{
                color: 'red'
              }} htmlFor="countryCode">
                Code: <br />
                <select name="countryCode" id="countryCode">
                  {countries.map((item, i) => {
                    return (
                      <option
                        selected={
                          parseInt(item.dial_code.replace("+", "")) ===
                          props.data.COUNTRY_CODE
                        }
                        value={item.dial_code.replace("+", "")}
                      >
                        {item.dial_code.replace("+", "") + " - " + item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="col-lg-2 col-sm-12">
              <label style={{
                color: 'red'
              }} htmlFor="count">
                Count: <br />
                <input
                  type="text"
                  defaultValue={props.data.COUNT}
                  name="count"
                  placeholder="Enter Member Count"
                  id="count"
                />
              </label>
            </div>
            <div className="col-lg-2 col-sm-12">
              <label style={{
                color: 'red'
              }} htmlFor="amount">
                Amount: <br />
                <input
                  type="text"
                  name="amount"
                  defaultValue={props.data.AMOUNT}
                  placeholder="Enter Amount"
                  id="amount"
                />
              </label>
            </div>
            <div className="col-lg-2 col-sm-12">
              <label style={{
                color: 'red'
              }} htmlFor="noofconsulation">
                No Cons: <br />
                <input
                  type="text"
                  name="noofconsulation"
                  defaultValue={props.data.NO_OF_CONSULTATIONS}
                  placeholder="Enter Number of Consultations"
                  id="noofconsulation"
                />
              </label>
            </div>
            <div className="col-lg-2 col-sm-12">
              <label style={{
                color: 'red'
              }} htmlFor="valid_month">
                Valid Days: <br />
                <input
                  type="text"
                  name="valid_month"
                  defaultValue={props.data.VALID_DAYS}
                  placeholder="Enter Valid Days"
                  id="valid_month"
                />
              </label>
            </div>
            <div

              className="col-lg-2 col-sm-12"
            >
              <label style={{
                color: 'red'
              }} htmlFor="percentage">
                Ref.%: <br />
                <input
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      document.getElementById("percentage").value = 0;
                    }
                  }}
                  type="number"
                  name="percentage"
                  defaultValue={props.data.PERCENTAGE}
                  placeholder="Ref.%"
                  id="percentage"
                />
              </label>

            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-10">
              <label htmlFor="narration">
                Narration: [seperation through dot(.)]<br />
                <textarea
                  defaultValue={props.data.NARRATION}
                  style={{
                    width: "100%",
                    height: 100,
                    border: '1px solid #212121',
                    borderRadius: 8,
                    padding: 5,
                    outline: 'none'
                  }}
                  row="10"
                  type="text"
                  name="narration"
                  placeholder="Enter Narration"
                  id="narration"
                />
              </label>
            </div>

            <div style={{
              textAlign: "center",
              display: "flex",
              position: "relative",
              top: 10,
              justifyContent: "center",
            }} className="col-2">
              <div>
                <h6>Is Active: </h6>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginRight: "20px",
                    }}
                  >
                    <input
                      type="radio"
                      name="isactive"
                      defaultChecked={props.data.IS_ACTIVE}
                      id="isactive"
                      value={"yes"}
                    />
                    <span style={{ marginLeft: "3px" }}>Yes</span>
                  </label>

                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <input
                      type="radio"
                      name="isactive"
                      defaultChecked={!props.data.IS_ACTIVE}
                      id="isactive"
                      value={"no"}
                    />
                    <span style={{ marginLeft: "3px" }}>No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <input
            type="button"
            onClick={handleEdit}
            className="auth-btn"
            value="Update Table"
          />
        </div>
      </div>
    </div>
  );
};
