import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  addLedgerMaster,
  deleteLedgerMasterDataById,
  deleteSchemeMaster,
  deleteTypeMaster,
  getCities,
  getCountries,
  getMastersId,
  getSchemes,
  getStates,
  getTypeId,
  updateLedgerMasters,
  updateLedgerPhotos,
  updateSchemeMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { AiFillCamera } from "react-icons/ai";
import Compressor from "compressorjs";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { getCookie } from "../../../../js/cookies";
import { getAllCallHistories } from "../../../../js/auth";
import { UpdatePrescription } from "../components/UpdatePrescription";


const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button style={{
        height: 50,
        borderRadius: 0
      }} className="btn btn-success" onClick={handleClick}>
        Export
      </button>
    </div>
  );
};

export const ViewCallRegister = (props) => {
  const [calls, setCalls] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [searchType, setSearchType] = useState("status");

  const [randomT, setRandomT] = useState(0);

  const [done, setDone] = useState(false);

  const [type, setType] = useState(null);

  const [admin, setAdmin] = useState({});

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");


  const [presId, setPresId] = useState(null);

  const [showPres, setShowPres] = useState(false);

  const acolumns = [
    {
      text: "ID",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row, index) => {
        return <p style={{
          fontWeight: 600,
          color: cell === "Missed Call" ? "red" : "green"
        }}>{cell}</p>
      }
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      sort: true,
    },
    {
      dataField: "doctor_id",
      text: "Doctor Ref ID",
      sort: true,
    },
    {
      dataField: "doctor_name",
      text: "Doctor Name",
      sort: true,
    },
    {
      dataField: "ring_time",
      text: "Ring Time",
      sort: true,
    },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row, index) => {
        var date = cell.slice(0, 10);
        var day = date.slice(8, 10);
        var month = date.slice(5, 7);
        var year = date.slice(0, 4);
        var dateDDMMYYYY = day + "-" + month + "-" + year;
        return dateDDMMYYYY;
      },
      sort: true,
    },
    {
      dataField: "no_of_stars",
      text: "Rating",
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, call, index) => {
        return <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {call.status !== "Missed Call" &&
            call.prescription_id !== null && admin.ROLE === 'admin' && (
              <MdEdit
                onClick={() => {
                  setPresId(call.prescription_id);
                  setShowPres(true);
                }}
                style={{
                  fontSize: 25,
                  position: "relative",
                  marginRight: 10,
                  cursor: "pointer",
                }}
              />
            )}
        </div>
      }
    },
  ];


  const rcolumns = [
    {
      text: "ID",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row, index) => {
        return <p style={{
          fontWeight: 600,
          color: cell === "Missed Call" ? "red" : "green"
        }}>{cell}</p>
      }
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      sort: true,
    },
    // {
    //   dataField: "ring_time",
    //   text: "Ring Time",
    //   sort: true,
    // },
    // {
    //   dataField: "start_time",
    //   text: "Start Time",
    //   sort: true,
    // },
    // {
    //   dataField: "end_time",
    //   text: "End Time",
    //   sort: true,
    // },
    // {
    //   dataField: "duration",
    //   text: "Duration",
    //   sort: true,
    // },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row, index) => {
        var date = cell.slice(0, 10);
        var day = date.slice(8, 10);
        var month = date.slice(5, 7);
        var year = date.slice(0, 4);
        var dateDDMMYYYY = day + "-" + month + "-" + year;
        return dateDDMMYYYY;
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "start_time",
      order: "desc",
    },
  ];


  const getDate = async () => {
    var d = new Date();
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    if (day.toString().length === 1) day = "0" + day;
    if (month.toString().length === 1) month = "0" + month;
    d = year + "-" + month + "-" + day;
    setStartDate(d);
    setEndDate(d);
    return d;
  };

  const filterUsingDate = (start, endD) => {
    var s_start = start;
    var s_end = endD;
    var sd = new Date(s_start).getTime();
    var ed = new Date(s_end);
    // add 1 more day to ed
    ed.setDate(ed.getDate() + 1);
    ed = ed.getTime();
    var t = backup;
    t = t.filter((d) => {
      var time = new Date(d.date).getTime();
      return sd < time && time < ed;
    });
    t = t.filter((d1)=>{
      return d1.doctor_id === d1.lm_reference_id
    })
    setCalls(t);
  };

  const getCallHistory = async (start, end) => {
    var res = await getAllCallHistories();
    if (res.success) {
      var t_calls = res.callLogs;
      setBackup(t_calls);
      var s_start = start === null ? await getDate() : start;
      console.log("Start=======================>");
      console.log(s_start);
      var s_end = end === null ? await getDate() : end;
      var sd = new Date(s_start).getTime();
      var ed = new Date(s_end);
      // add 1 more day to ed
      ed.setDate(ed.getDate() + 1);
      ed = ed.getTime();
      var t = t_calls;
      t = t.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd < time && time < ed;
      });
      t = t.filter((d1)=>{
        return d1.doctor_id === d1.lm_reference_id
      })
      setCalls(t);
    }
  };



  useEffect(() => {
    var a = getCookie("admin");
    if (a !== "") {
      a = JSON.parse(a);
      setAdmin(a);
    }
    var d = getDate();
    getCallHistory(null, null);
  }, []);
  return (
    !showPres ? <>
    <div
      style={{
        margin: 10,
        border: "3px solid #212121",
      }}
      className="panels"
    >
        
      <PanelHead disableMenu={true} title={"View Call Register"} />
      
      <div
        style={{
          padding: "15px",
        }}
      >
        

        <div
          style={{
            height: "100vh",
            position: "fixed",
            zIndex: 2,
            right: 0,
            paddingTop: 70,
            overflowY: "auto",
            boxShadow: "0 0 8px gray",
            backgroundColor: "#fff",
          }}
          className="prescription-cont-outside"
        >

        

        </div>


        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={calls}
            columns={admin.ROLE === 'admin' ? acolumns : rcolumns}
            defaultSorted={defaultSorted}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      position: "relative",
                      top: 5,
                      fontWeight: 600,
                    }}
                  >
                    FROM -{" "}
                  </span>
                  <input
                    id="startDate"
                    style={{
                      padding: 5,
                    }}
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      filterUsingDate(
                        e.target.value,
                        document.getElementById("endDate").value
                      );
                    }}
                    value={startDate}
                  />
                  <span
                    style={{
                      marginRight: 10,
                      marginLeft: 10,
                      position: "relative",
                      top: 5,
                      fontWeight: 600,
                    }}
                  >
                    TO -{" "}
                  </span>
                  <input
                    id="endDate"
                    style={{
                      padding: 5,
                    }}
                    type="date"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      filterUsingDate(
                        document.getElementById("startDate").value,
                        e.target.value
                      );
                    }}
                    value={endDate}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    height: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"status"}
                  >
                    <option value="id">ID</option>
                    <option value="status">STATUS</option>
                    <option value="patient_name">PATIENT NAME</option>
                    <option value="date">DATE</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = backup;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setCalls(l);
                      } else {
                        if (order === "asc") {
                          setCalls(backup);
                        } else {
                          setCalls(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search Ledger Master"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                 {
                  admin.ROLE === 'admin' &&  <MyExportCSV {...props.csvProps} />
                 }
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
    
    
    
    
    {/* <div
      style={{
        margin: 10,
        border: "3px solid #212121",
      }}
      className="panels"
    >

        
      <PanelHead disableMenu={true} title={"View Doctor Ratings"} />
      <div
        style={{
          padding: "15px",
        }}
      >
        
        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={calls}
            columns={admin.ROLE === 'admin' ? acolumns : rcolumns}
            defaultSorted={defaultSorted}
            exportCSV
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      marginRight: 10,
                      position: "relative",
                      top: 5,
                      fontWeight: 600,
                    }}
                  >
                    FROM -{" "}
                  </span>
                  <input
                    id="startDate"
                    style={{
                      padding: 5,
                    }}
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      filterUsingDate(
                        e.target.value,
                        document.getElementById("endDate").value
                      );
                    }}
                    value={startDate}
                  />
                  <span
                    style={{
                      marginRight: 10,
                      marginLeft: 10,
                      position: "relative",
                      top: 5,
                      fontWeight: 600,
                    }}
                  >
                    TO -{" "}
                  </span>
                  <input
                    id="endDate"
                    style={{
                      padding: 5,
                    }}
                    type="date"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      filterUsingDate(
                        document.getElementById("startDate").value,
                        e.target.value
                      );
                    }}
                    value={endDate}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    height: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"status"}
                  >
                    <option value="id">ID</option>
                    <option value="status">STATUS</option>
                    <option value="patient_name">PATIENT NAME</option>
                    <option value="date">DATE</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = backup;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setCalls(l);
                      } else {
                        if (order === "asc") {
                          setCalls(backup);
                        } else {
                          setCalls(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search Ledger Master"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                 {
                  admin.ROLE === 'admin' &&  <MyExportCSV {...props.csvProps} />
                 }
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div> */}
      </> :  <UpdatePrescription
              presId={presId}
              fromMob={false}
              closeBtnFunction={() => {
                setShowPres(false)
                setPresId(null)
              }}
            />
  );
};

