import { useContext, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosTrash } from "react-icons/io";
// import { createPrescription, getPrescriptionByLmId, getPrescriptionByPId, updateCallLog, updatePrescription } from "../../js/convo";
import {AiOutlineCloseCircle} from 'react-icons/ai';
// import { AppDataContext } from "../../context/AppContext";
import { getCookie } from "../../../../js/cookies";
import * as React from 'react';
import { getPrescriptionByPId } from "../../../../js/auth";


export const UpdatePrescription = (props) => {
  const [investigations, setInvestigations] = useState([]);
  const [prescription, setPrescription] = useState([]);

  const [currentPres, setCurrentPres] = useState({});


  const getPresId = async () => {
    var res = await getPrescriptionByPId(props.presId);
      
      if(res.success){
        if(res.bill !== null){
          console.log("heres the details=============>")
          setPrescription(res.prescriptions);
        setInvestigations(res.investigations);
        var bill = res.bill;
        var prescriptions = res.prescriptions;
        var investigations = res.investigations;
        var diagnosis = res.diagnosis;
        var currentPres = { ...bill};
        delete currentPres.id;
        delete currentPres.updatedAt;
        var t_pres = [];
        var pres_j = [];
        for(var i = 0;i<prescriptions.length;i++){
          var t = prescriptions[i];
          delete t.createdAt;
          delete t.updatedAt;
          delete t.prescription_id;
          t_pres.push(t);
        }
        for(var j = 0;j<investigations.length;j++){
          var j1 = investigations[j];
          delete j1.createdAt;
          delete j1.updatedAt;
          delete j1.prescription_id;
          pres_j.push(j1);
        }
        currentPres.prescriptions = t_pres;
        currentPres.investigations = pres_j;
        delete diagnosis.id;
        delete diagnosis.createdAt;
        delete diagnosis.updatedAt;
        delete diagnosis.prescription_id;
        currentPres = {...currentPres, ...diagnosis};
        }
      }
      else{
        alert(res.message);
      }

      setCurrentPres(currentPres);
  }


  useEffect(()=>{
      getPresId();
  }, [])

  return ( <div
      id="chatCont"
      className={props.fromMob ? "chat-mobile-cont" : "chat-cont"}
    >
      
      
      
      <div className="row">
              <div className="col">
                <p
                  style={{
                    padding: 5,
                    fontWeight: 600,
                  }}
                >
                  Prescription Id: {currentPres ? currentPres.prescription_id : ''}
                </p>
              </div>
              <div className="col">
                <p
                  style={{
                    padding: 5,
                    fontWeight: 600,
                  }}
                >
                  Date: {currentPres ? currentPres.createdAt : ''}
                </p>
              </div>
            </div>
      <div className="prescriptions">
        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[0];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[0];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Diagnosis</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div className="pres-accordian-bottom show">
            <br />
            <div className="row">
              <div className="col a">
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Chief Complaint/ Examination
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input readOnly checked={currentPres ? currentPres.print_chief : false} className="printCheck" type="checkbox" name="printCheifComplain" id="printCheifComplain" />
                    </label>
                  </div>
                  <textarea
                    name="complain"
                    readOnly
                    value={currentPres ? currentPres.chief_complain || "" : ""}
                    id="complain"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      History
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input readOnly checked={currentPres ? currentPres.print_history : false} className="printCheck" type="checkbox" name="printHistory" id="printHistory" />
                    </label>
                  </div>
                  <textarea
                    name="history"
                   readOnly
                    value={currentPres ? currentPres.history || "" : ""}
                    id="history"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Provinsional Diagnosis
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input readOnly checked={currentPres ? currentPres.print_examination : false} className="printCheck" type="checkbox" name="printExamprintHistory" id="printExamprintHistory" />
                    </label>
                  </div>
                  <textarea
                    name="examination"
                    readOnly
                    value={currentPres ? currentPres.examination || "" : ""}
                    id="examination"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div className="col a">
                <div>
                <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Advice
                    </p>

                    <label style={{
                      display: "flex",
                    }}>
                      <p style={{
                        marginRight: 5,
                        fontWeight: 600,
                        color: '#2d56a9'
                      }}>Print</p>
                    <input readOnly checked={currentPres ? currentPres.print_advice : false} className="printCheck" type="checkbox" name="printAdvice" id="printAdvice" />
                    </label>
                  </div>
                  <textarea
                    name="advice"
                     readOnly
                    value={currentPres ? currentPres.advice || "" : ""}
                    id="advice"
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[1];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[1];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Investigation</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div id="pres-accordian-bottom" className="pres-accordian-bottom show">
            <br />
           
{/* 
            <div className="row">
              <div className="col-4 inves">
                <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Department
                  </p>
                  
                  <input style={{display:'none'}} type="text" id="department" />
                  <SearchBox col={'col-4'} value={deptSearch} data={props.departments} onChange={(e)=>{
                    setDeptSearch(e);
                    document.getElementById('department').value = e;
                    var i = props.itemMasters;
                    var tests = [];
                    for(var j = 0; j < i.length; j++){
                      if(i[j].DEPARTMENT === e){
                        tests.push(i[j].ITEM_NAME);
                      }
                    }
                    setTestNames(tests);
                  }} placeholder="Enter Department"/>
                </div>
              </div>
              <div className="col-6 inves">
                <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Test Name
                  </p>
                 
                  <input style={{display:'none'}} type="text" id="testName" />
                  <SearchBox col={'col-6'} value={testSearch} data={testNames} onChange={(e)=>{
                     setTestSearch(e);
                    document.getElementById('testName').value = e;
                  }} placeholder="Enter Test Name"/>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: 'relative',
                  bottom: 10
                }}
                className="col-2 inves"
              >
                <input
                onClick={()=>{
                  if(deptSearch.trim() !== '' && testSearch.trim() !== ''){
                    if(props.departments.includes(deptSearch) && testNames.includes(testSearch)){
                      var len = currentPres.investigations ? currentPres.investigations.length : 0;
                    var map = {
                      id: len + 1,
                      department: deptSearch, 
                      test_name: testSearch
                    }
                    var got = false;
                    for(var i=0;i<currentPres.investigations.length;i++){
                      if(!got){
                        if(currentPres.investigations[i].department === deptSearch && currentPres.investigations[i].test_name === testSearch){
                          got = true;
                          var prompt = window.confirm("Investigation already added. Do you want to add again?");
                          if(!prompt){
                            return;
                          }
                        }
                      }
                    }
                    setInvestigations([...investigations, map])
                    appData.changePrescription("investigations", [...investigations, map]);
                    setDeptSearch('');
                    setTestSearch('');
                    }
                  }
                }}
                  style={{
                    width: "80%",
                    backgroundColor: "#163470",
                    position: "relative",
                    top: 7,
                    color: "#fff",
                    outline: 'none',
                    border: 'none',
                    borderRadius: 5,
                    padding: 5,
                  }}
                  type="button"
                  value="Add"
                />
              </div>
            </div> */}
           <div style={{
            display: 'flex',
            justifyContent: 'center',
           }}>
           <table id="pres-acc-table">
              <thead>
                <tr>
                  <th>Department</th>
                  <th>Test Name</th>
                </tr>
              </thead>
              <tbody>
                {
             currentPres ?  currentPres.investigations && currentPres.investigations.map((item, i)=>{
                    return <tr>
                    <td>{item.department}</td>
                    <td>{item.test_name}</td>
                  </tr>
                  }) : ''
                }
                </tbody>
            </table>
           </div>
          </div>
        </div>

        <div className="pres-accordian">
          <div
            onClick={() => {
              var icon = document.getElementsByClassName("accordian-icon")[2];
              var bottom = document.getElementsByClassName(
                "pres-accordian-bottom"
              )[2];
              if (icon.classList.contains("down")) {
                icon.classList.remove("down");
              } else {
                icon.classList.add("down");
              }
              if (bottom.classList.contains("show")) {
                bottom.classList.remove("show");
              } else {
                bottom.classList.add("show");
              }
            }}
            className="pres-accordian-top"
          >
            <p>Prescription</p>
            <IoIosArrowDown
              className="accordian-icon down"
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
          </div>
          <div className="pres-accordian-bottom show">
            <br />

            {/* <div style={{
              display: 'flex',

            }} className="s">
              <div className="col-4 inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                    Medicine
                  </p>
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="medicine" id="medicine">
                    {
                      medicines.map((medicine)=>{
                        return <option value={medicine}>{medicine}</option>
                      }
                      )
                    }
                  </select> */}
                  {/* <input style={{display:'none'}} type="text" id="medicine" />
                  <SearchBox col={'col-3'} value={medicineSearch} data={medicines} onChange={(e)=>{
                     setMedicineSearch(e);
                    document.getElementById('medicine').value = e;
                  }} placeholder="Enter Medicine"/>
                </div>
              </div>
              <div style={{
                
              }} className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,

                    }}
                  >
                   Dose
                  </p>
                  <input style={{display:'none'}} type="text" id="dose" />
                  <SearchBox col={'col-3'} value={doseSearch} data={dose} onChange={(e)=>{
                     setDoseSearch(e);
                     document.getElementById('dose').value = e;
                    var dose = e;
                    if(dose !== ""){
                      dose = dose.split("-");
                      var count = 0;
                      for(var i=0;i<dose.length;i++){
                        if(dose[i].trim() === '1'){
                          count++;
                        } else if(dose[i].trim() === '1/2'){
                          count += 0.5;
                        }
                      }
                      var final = count * parseInt(document.getElementById('days').value);
                      document.getElementById('quantity').value = Math.ceil(final);
                    }
                    else{
                      document.getElementById('quantity').value = "0";
                    }
                  }} placeholder="Enter Dose"/>
                </div>
              </div>
              <div style={{
                width:50
              }} className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                      width: 50,
                    }}
                  >
                   Days
                  </p>
                  <input onChange={()=>{
                    var dose = document.getElementById('dose').value;
                    if(dose !== ""){
                      dose = dose.split("-");
                      var count = 0;
                      for(var i=0;i<dose.length;i++){
                        if(dose[i].trim() === '1'){
                          count++;
                        } else if(dose[i].trim() === '1/2'){
                          count += 0.5;
                        }
                      }
                      var final = count * parseInt(document.getElementById('days').value);
                      document.getElementById('quantity').value = Math.ceil(final);
                    }
                    else{
                      document.getElementById('quantity').value = "0";
                    }
                  }} style={{
                    position: 'relative',
                    bottom: 25
                  }} defaultValue={1} placeholder="Days" type="number" name="days" id="days" />
                </div>
              </div>
              <div className="col inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                      width: 50,
                    }}
                  >
                   Quantity
                  </p>
                  <input readOnly={true} style={{
                    position: 'relative',
                    bottom: 25
                  }} defaultValue={1} placeholder="Quantity" type="number" name="quantity" id="quantity" />
                </div>
              </div>
              <div className="col-5  inves">
              <div style={{
                  position: 'relative'
                }}>
                  <p
                    style={{
                      padding: 5,
                      fontWeight: 600,
                    }}
                  >
                   Dosage Interval
                  </p> */}
                  {/* <select style={{
                    position: 'relative',
                    bottom: 25
                  }} name="remarks" id="remarks">
                    {
                      remarks.map((remark)=>{
                        return <option value={remark}>{remark}</option>
                      }
                      )
                    }
                  </select> */}
                  {/* <input style={{display:'none'}} type="text" id="remarks" />
                  <SearchBox col={'col-3'} value={remarksSearch} data={remarks} onChange={(e)=>{
                     setRemarksSearch(e);
                    document.getElementById('remarks').value = e;
                  }} placeholder="Enter Dosage Interval"/>
                </div>
              </div>
              
            </div> */} 
            {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="col inves"
              >
                <input
                onClick={()=>{
                  var days = document.getElementById('days').value;
                  var quantity = document.getElementById('quantity').value;
                  if(medicineSearch.trim() !== '' && doseSearch.trim() !== ''){
                    if(medicines.includes(medicineSearch) && dose.includes(doseSearch)){
                      var len2 = currentPres.prescriptions ? currentPres.prescriptions.length : 0;
                  var map = {
                    id: len2 + 1,
                    medicine: medicineSearch,
                    dose: doseSearch,
                    days: parseInt(days),
                    quantity: parseInt(quantity),
                    remarks: remarksSearch
                  }
                  var got = false;
                  if(len2 > 0){
                    for(var i=0;i<currentPres.prescriptions.length;i++){
                      if(!got){
                        if(currentPres.prescriptions[i].medicine === map.medicine && currentPres.prescriptions[i].dose === map.dose && currentPres.prescriptions[i].remarks === map.remarks){
                          got = true;
                          var prompt = window.confirm("Prescription already added. Do you want to add again?");
                          if(!prompt){
                            return;
                          }
                        }
                      }
                    }
                  }
                  setPrescription([...prescription, map]);
                  appData.changePrescription("prescriptions", [...prescription, map]);
                  setMedicineSearch('');
                  setDoseSearch('');
                  setRemarksSearch('');
                  document.getElementById('quantity').value = 1;
                  document.getElementById('days').value = 1;
                    }
                  }
                }}
                  style={{
                    width: 150,
                    backgroundColor: "#163470",
                    position: "relative",
                    color: "#fff",
                    outline: 'none',
                    border: 'none',
                    borderRadius: 5,
                    padding: 5,
                  bottom: 25
                  }}
                  type="button"
                  value="Add"
                />
              </div> */}
            <div style={{
            display: 'flex',
            justifyContent: 'center',
           }}>
           <table id="pres-acc-table">
              <thead>
                <tr>
                  <th>Medicine</th>
                  <th>Dose</th>
                  <th>Days</th>
                  <th>Quantity</th>
                  <th>Dosage Interval</th>
                </tr>
              </thead>
              <tbody>
                {
                 currentPres ? currentPres.prescriptions && currentPres.prescriptions.map((item, i)=>{
                    return <tr>
                    <td>{item.medicine}</td>
                    <td>{item.dose}</td>
                    <td>{item.days}</td>
                    <td>{item.quantity}</td>
                    <td>{item.remarks}</td>
                  </tr>
                  }) : ''
                }
                </tbody>
            </table>
           </div>
          </div>
          
          <br />

          <label style={{
            padding: 10,
            display: 'flex',
          }}>
            <p style={{
              fontWeight: 600,
              paddingRight: 10,
              position:'relative',
              top: 10
            }}>Next Visit</p>
          <input readOnly value={currentPres ? currentPres.next_consultation_date || "" : ""} style={{
            width: 100
          }} type="number" />

<p style={{
              fontWeight: 600,
              paddingLeft: 10,
              position:'relative',
              top: 10
            }}>Days</p>

          </label>

          <br />

          <div style={{
            display: 'flex',
            padding: 5,
            width: '100%',
          }}>
         

          <button onClick={()=>{
            props.closeBtnFunction();
          }} style={{
            backgroundColor: '#163470',
            color: '#fff',
            padding: 15,
            borderRadius: 5,
            outline: 'none',
            border: 'none',
            marginRight: 10,
          }}>Close</button>
          </div>
          </div>


        </div>
      </div>
  );
};
