import { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import {IoArrowBackOutline} from 'react-icons/io5';

const PanelHead = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.screen.width);
  const setWindowDimensions = () => {
    setWindowWidth(window.screen.width);

    if(windowWidth < 800){
        document.getElementById("side_bar").style.width = "0px";
    }
    else{
        document.getElementById("side_bar").style.width = "280px";
    }
  };
  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, [windowWidth]);

  

  const handleSideBar = () => {
    
      document.getElementById("side_bar").style.width = "280px";
    
  };

  return (
    <div style={{
      backgroundColor: "#2d55a8",
      color: '#fff',
      padding: "10px",
    }} className="panelHead">
      {
        !props.disableMenu && <HiMenuAlt1
        onClick={() => {
          handleSideBar();
        }}
        className="p-head-icon"
      /> 
      }
      <div style={{
        display: "flex",
      }}>
      {
        props.showIcon !== undefined && props.showIcon ? <IoArrowBackOutline style={{
          fontSize: "25px",
          marginRight: "10px",
          cursor: "pointer",
        }}  onClick={() => {
          props.setMode("view", {});
          props.setHideMain(false);
        }}/> : null
      }
      <h4>{props.title}</h4>
      </div>
    </div>
  );
};

export default PanelHead;
