import { useEffect, useState } from "react";
import PanelHead from "../components/PanelHead";
import {
  deleteTypeNameMaster,
  getMastersId,
  updateTypeNameMaster,
} from "../../../../js/formsDB";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export const ViewTypeNameMaster = (props) => {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({});

  const changeMode = (mode, data) => {
    setData(data);
    setMode(mode);
  };

  if (mode === "view") {
    return <ViewTypeNameMaster1 {...props} setMode={changeMode} />;
  } else if (mode === "edit") {
    return <EditTypeNameMaster {...props} data={data} setMode={changeMode} />;
  }
};

export const ViewTypeNameMaster1 = (props) => {
  const [masters, setMasters] = useState([]);

  const [backup, setBackup] = useState([]);

  const [order, setOrder] = useState("asc");

  const [randomT, setRandomT] = useState(0);

  const [searchType, setSearchType] = useState("MASTER_TYPE_NAME");

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (props.masters.length !== 0) {
      if (randomT !== props.randT) {
        setMasters(props.masters);
        setBackup(props.masters);
        setDone(true);
        setRandomT(props.randT);
      }
    }
  });

  const columns = [
    {
      dataField: "MASTER_ID",
      text: "MASTER ID",
      sort: true,
    },
    {
      dataField: "MASTER_TYPE_NAME",
      text: "TYPE NAME",
      sort: true,
    },
    {
      dataField: "MASTER_REMARKS_DISPLAY",
      text: "REMARKS",
      sort: true,
    },
    {
      dataField: "IS_ACTIVE",
      text: "ACTIVE",
      sort: true,
    },
    {
      dataField: "MASTER_IS_GROUP",
      text: "GROUP",
      sort: true,
    },
    {
      dataField: "actions",
      text: "ACTIONS",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BsFillPencilFill
            onClick={() => {
              props.setMode("edit", row);
              props.setHideMain(true);
            }}
            style={{
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
              color: "#2d55a8",
            }}
          />
        </div>
      ),
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button style={{
          height: 50,
          borderRadius: 0
        }} className="btn btn-success" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: 50,
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead disableMenu={true} title={"View Type Name Master"} />
      <div
        style={{
          padding: 15,
        }}
      >
        <div className="panel_cont">
          <ToolkitProvider
            keyField="id"
            data={masters}
            columns={columns}
            exportCSV
          >
            {(props) => (
              <div>

                <div
                  style={{
                    display: "flex",
                    height: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                    defaultValue={"MASTER_TYPE_NAME"}
                  >
                    <option value="MASTER_ID">MASTER ID</option>
                    <option value="MASTER_TYPE_NAME">TYPE NAME</option>
                    <option value="MASTER_REMARKS_DISPLAY">REMARKS</option>
                  </select>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        var l = masters;
                        l = l.filter((item) => {
                          if (item[searchType] !== null) {
                            return item[searchType]
                              .toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          }

                        });

                        setMasters(l);
                      } else {
                        if (order === "asc") {
                          setMasters(backup);
                        } else {
                          setMasters(backup.reverse());
                        }
                      }
                    }}
                    placeholder="Search Type Master"
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px solid black",
                      outline: "none",
                      padding: "5px",
                    }}
                    type="text"
                  />
                  <MyExportCSV {...props.csvProps} />
                </div>

                <hr />
                <BootstrapTable
                  hover
                  striped
                  condensed
                  headerWrapperClasses="foo"
                  pagination={paginationFactory()}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          {/* <table id="customers">
          <thead>
            <tr>
              <th style={{
                width: 200
              }}>MASTER ID</th>
              <th style={{
                width: 400
              }}>TYPE NAME</th>
              <th style={{
                width: 200
              }}>REMARKS</th>
              <th style={{
                width: 200
              }}>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {
                masters.map((master)=>{
                    return (
                        <tr>
                            <td>{master.MASTER_ID}</td>
                            <td>{master.MASTER_TYPE_NAME}</td>
                            <td>{master.MASTER_REMARKS_DISPLAY ? "Yes" : "No"}</td>
                            <td>
                               <div style={{
                                textAlign: 'center',
                               }}>
                               <BsFillPencilFill onClick={()=>{
                                props.setMode("edit", master);
                                props.setHideMain(true);
                               }} style={{
                                    marginRight: "10px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "#2d55a8",
                                }}/>
                                <MdDelete onClick={async()=>{
                                    var y = window.confirm("Are you sure you want to delete this record?");
                                    if(y){
                                      var res = await deleteTypeNameMaster(master.MASTER_ID);
                                    if(res.success){
                                        alert("Deleted Successfully");
                                        var t = masters.filter((m)=>{
                                            return m.MASTER_ID !== master.MASTER_ID;
                                        }
                                        );
                                        setMasters(t);
                                        props.getM();
                                    }
                                    else{
                                        alert("Error Occured");
                                    }
                                    }
                                }} style={{
                                    marginLeft: "10px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "red",
                                }} />
                               </div>
                            </td>
                        </tr>
                    )
                })
            }
          </tbody>
        </table> */}
        </div>
      </div>
    </div>
  );
};

export const EditTypeNameMaster = (props) => {
  useEffect(() => { }, []);

  const handleEdit = async () => {
    var tname = document.getElementById("e_mname").value;
    var is_active = document.querySelector(
      "input[name = e_isactive]:checked"
    ).value;
    var is_group = document.querySelector(
      "input[name = e_isgroup]:checked"
    ).value;
    var remarks_display = document.querySelector(
      "input[name = e_remarksDisplay]:checked"
    ).value;

    if (tname === "") {
      alert("Type Name is required");
      return;
    }

    var data = {
      MASTER_TYPE_NAME: tname,
      IS_ACTIVE: is_active === "yes" ? true : false,
      MASTER_IS_GROUP: is_group === "yes" ? true : false,
      MASTER_REMARKS_DISPLAY: remarks_display === "yes" ? true : false,
    };

    var res = await updateTypeNameMaster(data, props.data.MASTER_ID);

    if (res.success) {
      alert("Updated Successfully");
      props.getM();
      props.setMode("view");
      props.setHideMain(false);
    } else {
      alert("Error Occured");
    }
  };
  return (
    <div
      style={{
        border: "3px solid #212121",
      }}
      className="panels"
    >
      <PanelHead
        showIcon={true}
        setHideMain={props.setHideMain}
        setMode={props.setMode}
        title={"Edit Type Name Master"}
      />
      <div className="panel_cont">
        <div style={{ display: "flex" }} className="head"></div>
        <br />
        <div
          style={{
            padding: 15,
          }}
          className="edit-form"
        >
          <div className="row">
            <div className="col-4">
              <h6>Master ID: </h6>
              <label htmlFor="e_mid">
                <input
                  readOnly={true}
                  defaultValue={props.data.MASTER_ID}
                  style={{
                    backgroundColor: "#e0e0e0",
                  }}
                  type="text"
                  name="e_mid"
                  placeholder="Master ID"
                  id="e_mid"
                />
              </label>
            </div>

            <div className="col-8">
              <h6
                style={{
                  color: "red",
                }}
              >
                Type Name:{" "}
              </h6>
              <label htmlFor="e_name">
                <input
                  type="text"
                  defaultValue={props.data.MASTER_TYPE_NAME}
                  name="e_name"
                  placeholder="Enter Type Name"
                  id="e_mname"
                />
              </label>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col">
              <h6>Is Active: </h6>
              <div style={{ marginTop: "10px", display: "flex" }}>
                <label
                  style={{
                    width: "fit-content",
                    display: "flex",
                    marginRight: "20px",
                  }}
                >
                  <input
                    type="radio"
                    name="e_isactive"
                    defaultChecked={props.data.IS_ACTIVE}
                    id="e_isactive"
                    value={"yes"}
                  />
                  <span style={{ marginLeft: "3px" }}>Yes</span>
                </label>

                <label
                  style={{
                    width: "fit-content",
                    display: "flex",
                  }}
                >
                  <input
                    type="radio"
                    name="e_isactive"
                    defaultChecked={!props.data.IS_ACTIVE}
                    id="e_isactive"
                    value={"no"}
                  />
                  <span style={{ marginLeft: "3px" }}>No</span>
                </label>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
              className="col"
            >
              <div>
                <h6>Is Group</h6>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginRight: "20px",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_isgroup"
                      defaultChecked={props.data.MASTER_IS_GROUP}
                      id="e_isgroup"
                      value={"yes"}
                    />
                    <span style={{ marginLeft: "3px" }}>Yes</span>
                  </label>

                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_isgroup"
                      defaultChecked={!props.data.MASTER_IS_GROUP}
                      id="e_isgroup"
                      value={"no"}
                    />
                    <span style={{ marginLeft: "3px" }}>No</span>
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
              className="col"
            >
              <div>
                <h6>Remarks Display</h6>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginRight: "20px",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_remarksDisplay"
                      defaultChecked={props.data.MASTER_REMARKS_DISPLAY}
                      id="e_remarksDisplay"
                      value={"yes"}
                    />
                    <span style={{ marginLeft: "3px" }}>Yes</span>
                  </label>

                  <label
                    style={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <input
                      type="radio"
                      name="e_remarksDisplay"
                      defaultChecked={!props.data.MASTER_REMARKS_DISPLAY}
                      id="e_remarksDisplay"
                      value={"no"}
                    />
                    <span style={{ marginLeft: "3px" }}>No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <input
            onClick={handleEdit}
            type="button"
            className="auth-btn"
            value="Update Table"
          />
        </div>
      </div>
    </div>
  );
};
