import axios from "axios";

// axios.defaults.withCredentials = true;

const config = { headers: { "Content-Type": "application/json" } };

const imageConfig = { headers: { "Content-Type": "multipart/form-data" } };

const urlHead = 'https://server.liveopd.in/api/v2/';
// const urlHead = 'http://localhost:8000/api/v2/';

export const postTypeNameMaster = async (map) => {
  const url = urlHead + "postTypeNameMasterData";
  try {
    const response = await axios.post(url, map, config);

    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const postTypeMasterData = async (map) => {
  const url = urlHead + "postTypeMasterData";
  try {
    const response = await axios.post(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const postItemMasterData = async (map) => {
  const url = urlHead + "postItemMasterData";
  try {
    const response = await axios.post(url, map, config);
    const body = await response.data;

    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const postSchemeMasterData = async (map) => {
  const url = urlHead + "postSchemeMasterData";
  try {
    const response = await axios.post(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const postFreeAccessLedger = async (map) => {
  const url = urlHead + "postFreeAccessLedger";
  try {
    const response = await axios.post(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};


export const getMastersId = async () => {
  const url = urlHead + "getTypeNameMasterData";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const getTypeId = async () => {
  const url = urlHead + "getTypeMasterData";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const getSchemes = async () => {
  const url = urlHead + "getSchemeMasterData";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const getActiveSchemes = async () => {
  const url = urlHead + "getAllActiveSubscriptions";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};



export const getFreeAccessLedgers = async () => {
  const url = urlHead + "getFreeAccessLedgers";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
}

export const getItemsId = async () => {
  const url = urlHead + "getItemMasterData";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const updateTypeNameMaster = async (map, id) => {
  const url = urlHead + "updateTypeNameMasterData/" + id;
  try {
    const response = await axios.put(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const deleteTypeNameMaster = async (id) => {
  const url = urlHead + "deleteTypeNameMasterData/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const updateTypeMaster = async (map, id) => {
  const url = urlHead + "updateTypeMasterData/" + id;
  try {
    const response = await axios.put(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const deleteTypeMaster = async (id) => {
  const url = urlHead + "deleteTypeMasterData/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};


export const deleteFreeAccessLedger = async (id) => {
  const url = urlHead + "deleteFreeAccessLedger/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};


export const deletePaymentLog = async (id) => {
  const url = urlHead + "deletePaymentLog/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const updateSchemeMaster = async (map, id) => {
  const url = urlHead + "updateSchemeMasterData/" + id;
  try {
    const response = await axios.put(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};


export const updateFreeAcessLedger = async (map, id) => {
  const url = urlHead + "updateFreeAcessLedger/" + id;
  try {
    const response = await axios.put(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};


export const deleteSchemeMaster = async (id) => {
  const url = urlHead + "deleteSchemeMasterData/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const updateItemMaster = async (map, id) => {
  const url = urlHead + "updateItemMasterData/" + id;
  try {
    const response = await axios.put(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const deleteItemMaster = async (id) => {
  const url = urlHead + "deleteItemMasterData/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const getLedgerMasters = async () => {
  const url = urlHead + "getLedgerMasterData";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const addLedgerMaster = async (map) => {
  const url = urlHead + "postLedgerMasterData";
  try {
    var formData = new FormData();
    Object.keys(map).forEach((key) => {
      formData.append(key, map[key]);
    });
    const response = await axios.post(url, formData, imageConfig);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const updateLedgerMasters = async (map, id) => {
  const url = urlHead + "updateLedgerMasterDataById/" + id;
  try {
    var formData = new FormData();
    Object.keys(map).forEach((key) => {
      formData.append(key, map[key]);
    });
    const response = await axios.put(url, formData, imageConfig);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const deleteLedgerMasterDataById = async (id) => {
  const url = urlHead + "deleteLedgerMasterDataById/" + id;
  try {
    const response = await axios.delete(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
};

export const addLedgerPhotos = async (map) => {
  
  if (map.profile) {
    
    const url = urlHead + "createProfilePicture/" + map.id;
    try {
      const formData = new FormData();
      formData.append('image', map.profile);
      for(var pair of formData.entries()) {
        
      }
      const response = await axios.post(url, formData, imageConfig);
      const body = await response.data;
      return body;
    } catch (e) {
      
      var error = await e.response.data.message;
      return { success: false, message: error };
    }
  }
  if (map.signature) {
    
    const url = urlHead + "createSignature/" + map.id;
    try {
      const formData = new FormData();
      formData.append('image', map.signature);
      const response = await axios.post(url, formData, imageConfig);
      const body = await response.data;
      return body;
    } catch (e) {
      var error2 = await e.response.data.message;
      return { success: false, message: error2 };
    }
  }
};

export const updateLedgerPhotos = async (map) => {
  console.log("gulu==============.");
  console.log(map)
  if (map.profile) {
    const url = urlHead + "updateProfilePicture/" + map.id;
    try {
      const formData = new FormData();
      formData.append('image', map.profile);
      const response = await axios.put(url, formData, imageConfig);
      const body = await response.data;
      return body;
    } catch (e) {
      var error = await e.response.data.message;
      return { success: false, message: error };
    }
  }
  if (map.signature) {
    console.log("signature ob back======================>")
    const url = urlHead + "updateSignature/" + map.id;
    try {
      const formData = new FormData();
      formData.append('image', map.signature);
      console.log("go===========>")
      const response = await axios.put(url, formData, imageConfig);;
      const body = await response.data;
      return body;
    } catch (e) {
      var error2 = await e.response.data.message;
      return { success: false, message: error2 };
    }
  }
};



export const createCallQueue = async (map) => {
  const url = urlHead + "createCallQueue";
  try {
    const response = await axios.post(url, map, config);
    const body = await response.data;
    return body;
  } catch (e) {
    var error = await e.response.data.message;
    
    return { success: false, message: error };
  }
}


export const getCountries = async () => {
  const url = urlHead + "getCountries";
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
}

export const getStates = async (id) => {
  const url = urlHead + "getStatesByPhoneCode/" + id;
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
}

export const getCities = async (id) => {
  const url = urlHead + "getCities/" + id;
  try {
    const response = await axios.get(url, config);
    const body = await response.data;
    return body;
  } catch (e) {
    
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
}

export const getAgentReports = async (sd, ed) => {
  const url = urlHead + "getReferralDetailsToExport";
  try {
    const response = await axios.post(url,  {
      from_date: sd,
      to_date: ed,
    }, config);
    const body = await response.data;
    return body;
  } catch (e) {
    
    var error = await e.response.data.message;
    return { success: false, message: error };
  }
}